import React from 'react';
import { CountryModel, CPCModel, MaterialPropertyModel } from 'services/EpdClient';
import {
  DictionaryValues,
  EPDAdditionalInformationModel,
  EPDCoverPageModel,
  EPDDefinitionModel,
  EPDOrganizationInformationAddressModel,
  EPDOrganizationInformationModel,
  LcaSupportModel,
  PCRInformationModel,
  ThirdPartyVerificationModel,
  VerificationViewModel,
  EPDProductInformationModel,
  EPDDeclareContentProductModel,
  InfrastructureAndCapitalGoodsModel,
  DataQualityAssessmentReadModel,
  EPDDataSourceModel,
  DataSourceOptionModel,
  LCASpecificationModel,
  LCASoftwareOptionModel,
  ReferencePackageVersion15804,
  CO2UptakeAssociationModel,
  SystemBoundaryModel,
  SystemBoundaryGridModel,
  ScenarioItemModel,
  LcaResultsViewModel,
  LcaCustomResultModel,
} from 'types/types';

export interface EpdVersionHistoryData {
  epdVersionId: string;
  epdDefinitionInformation: EPDDefinitionModel;
  organizationInformation: EPDOrganizationInformationModel;
  organizationInformationAddress: EPDOrganizationInformationAddressModel;
  pcrInformation: PCRInformationModel;
  lcaSupportInformation: LcaSupportModel;
  thirdPartyVerification: ThirdPartyVerificationModel;
  verificationReportInfo: VerificationViewModel;
  additionalInformation: EPDAdditionalInformationModel | null | undefined;
  coverPageInformation: EPDCoverPageModel;
  productsInformation: EPDProductInformationModel[];
  contentDeclarationProducts: EPDDeclareContentProductModel[];
  infrastructureAndCapitalGoods: InfrastructureAndCapitalGoodsModel | null | undefined;
  dataQualityAssessmentAndReferenceYears: DataQualityAssessmentReadModel;
  lcaSpecification: LCASpecificationModel | null | undefined;
  cO2UptakeAssociation: CO2UptakeAssociationModel;
  systemBoundary: SystemBoundaryModel | null | undefined;
  systemBoundaryGrid: SystemBoundaryGridModel;
  scenarios: ScenarioItemModel[];
  lcaResults: LcaResultsViewModel;
  lcaCustomResults: LcaCustomResultModel[];
  dataSources: EPDDataSourceModel[];
  countries: CountryModel[];
  dictionaries: DictionaryValues;
  cpcCodes: CPCModel[];
  materialPropertiesLookup: MaterialPropertyModel[];
  dataSourceOptions: DataSourceOptionModel[];
  lcaSoftwareOptions: LCASoftwareOptionModel[];
  referencePackageVersions15804: { versions: ReferencePackageVersion15804[] };
}

const EpdVersionHistoryDataContext = React.createContext<EpdVersionHistoryData | null>(null);
export default EpdVersionHistoryDataContext;

export function useEpdVersionHistoryData(): EpdVersionHistoryData {
  const data = React.useContext(EpdVersionHistoryDataContext);
  if (!data) {
    throw new Error('This hook must be used when all data is loaded.');
  }
  return data;
}

export function useEpdVersionHistoryDataOrWhileLoadingThenNull(): EpdVersionHistoryData | null {
  return React.useContext(EpdVersionHistoryDataContext);
}
