import UploadFile, { docxOrPdfType } from 'components/form/UploadFile';
import HelpIcon from 'components/icons/HelpIcon';
import { EPD_DEVELOPMENT_VERIFICATION_TEMPLATES_URL } from 'constants/constants';
import helpIcon from 'images/icons/svg/info.svg';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionState, EPDModel } from 'services/EpdClient';
import styled from 'styled-components';
import { A, InfoBox } from 'styles/Styles.styled';
import { formatDate } from 'util/utils';

import { Validation } from './Validation';
import { FixedLabel, FixedLabelTop, FixedTextInput } from './styles';
import { WizardStepValidationSchema } from './types';

const WizardStep3: React.FunctionComponent<{
  epd?: EPDModel;
  onChangeEpd?: any;
  setUpdatedEpd?: (updatedEpd: EPDModel) => void;
  goToStep?: (step: number) => void;
  validation: WizardStepValidationSchema;
}> = ({ epd, onChangeEpd, validation, goToStep }) => {
  const { t } = useTranslation();

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];

    if (!file || !epd) {
      return null;
    }

    onChangeEpd && onChangeEpd(propertyName, file);
  };

  return (
    <div>
      <FixedRow style={{ gridTemplateRows: '50px' }} state={epd?.epdVerifierState?.actionState}>
        <FixedLabelTop>{t('epdWizard.wizardStep3.properties.verifier')}</FixedLabelTop>
        {epd?.epdVerifier?.displayName ?? (
          <>
            <div style={{ minWidth: '40%' }}>{t('epdWizard.wizardStep3.properties.declaration')}</div>
            <div style={{ margin: '1rem 0 1rem 0', minWidth: '30%' }}>
              <StepLink
                style={{ color: 'inherit', textDecoration: 'none' }}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  goToStep?.(0);
                }}
              >
                {t('epdWizard.wizardStep3.properties.getStarted')}
              </StepLink>
            </div>
          </>
        )}
      </FixedRow>

      <FixedRow state={epd?.verificationReportsState?.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep3.properties.verificationReport')}
          <Validation validation={validation} field="verificationReports" epd={epd} />
        </FixedLabelTop>
        <div>
          {epd?.verificationTemplateUrlState?.actionState !== ActionState.Hidden && (
            <InfoBox>
              <img src={helpIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
              <div>
                {t('epdWizard.wizardStep3.info.verifier')}
                <br />
                <A href={EPD_DEVELOPMENT_VERIFICATION_TEMPLATES_URL} target="_blank" rel="noopener noreferrer">
                  {t('download.selectTemplate')}
                </A>
              </div>
            </InfoBox>
          )}
          <UploadFile
            accept={docxOrPdfType}
            validExtensions={['docx', 'pdf']}
            title={t('epdWizard.wizardStep3.upload.verificationReport')}
            uploadFile={uploadFile}
            disabled={epd?.verificationReportsState?.actionState === ActionState.Disabled}
            documents={epd?.verificationReports}
            onChangeFile={onChangeEpd}
            field="VerificationReport"
          />
        </div>
      </FixedRow>

      <FixedRow state={epd?.processCertificatesState?.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep3.properties.processCertificates')}
          <Validation validation={validation} field="processCertificates" epd={epd} />
        </FixedLabelTop>
        <div style={{ width: '100%' }}>
          <UploadFile
            accept=".pdf"
            title={t('epdWizard.wizardStep3.upload.processCertificates')}
            uploadFile={uploadFile}
            disabled={epd?.processCertificatesState?.actionState === ActionState.Disabled}
            documents={epd?.processCertificates}
            onChangeFile={onChangeEpd}
            field="processCertificate"
          />
        </div>
      </FixedRow>
      <FixedRow state={epd?.dataSetReferenceYearState?.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep3.properties.referenceYear')}
          <HelpIcon content={t('epdWizard.wizardStep3.tooltips.dataSetReferenceYear')} />
        </FixedLabel>
        <FixedTextInput
          value={epd?.dataSetReferenceYear}
          type={'number'}
          disabled={epd?.dataSetReferenceYearState?.actionState === ActionState.Disabled}
        />
      </FixedRow>
      <FixedRow state={epd?.approvalDateState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep3.properties.approvalDate')}
          <Validation validation={validation} field="approvalDate" epd={epd} />
        </FixedLabel>
        {epd?.approvalDateState.actionState !== ActionState.Enabled ? (
          <em>{formatDate(epd?.approvalDate)}</em>
        ) : (
          <FixedTextInput
            value={formatDate(epd?.approvalDate)}
            type={'date'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              e.currentTarget.value !== formatDate(epd?.approvalDate) &&
              onChangeEpd('approvalDate', new Date(e.currentTarget.value))
            }
          />
        )}
      </FixedRow>
    </div>
  );
};

const StepLink = styled.a`
  padding: 0.5rem 1rem;
  border: solid 1px ${(props) => props.theme.colors.gray};
  font-family: ${(props) => props.theme.fontFamilies.heading};
  font-size: 1.1rem;
  font-style: normal;
  text-decoration: none;
  color: text;

  :hover {
    background: ${(props) => props.theme.colors.lightGray};
  }

  ${(props) => props.theme.media.tablet} {
    margin: 0.5rem;
  }
`;

export const FixedRow = styled.div<{ state?: ActionState }>`
  display: ${(props) => (props?.state === ActionState.Hidden ? 'none' : 'flex')};
  padding: 0.5rem 0;
  margin-top: 2rem;
  flex-wrap: wrap;
  align-items: center;

  ${(props) => props.theme.media.tablet} {
    flex-wrap: nowrap;
    margin-top: 0;
  }
`;

export default WizardStep3;
