import { Column, ColumnBodyOptions, ColumnEvent } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import React from 'react';
import {
  useCreateEpdProductComponent,
  useDeleteProductComponents,
  useUpdateEpdProductComponent,
} from 'services/api/mutations';
import { useEPDDefinitionInformation, useEpdProductComponents } from 'services/api/queries';
import { EPDContentDeclarationProductType, ProductComponent } from 'types/types';

import { ISO_21930_ID, YES_NO } from '../../constants';
import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';
import DynamicGridHeader from '../DynamicGridHeader';
import { cellNumberEditor, cellTextEditor } from '../GridCellEditors';
import { cellTemplateWithPlaceholder, cellTotalTemplate, onCellEdit } from '../GridService';
import { DataTableStyled } from '../StyledGrid';
import useGridRowSelectionViaCheckbox from '../useGridRowSelectionViaCheckbox';

const ProductComponentsGrid: React.FunctionComponent<{
  epdVersionId: string;
  serviceIncludesProductInSystemBoundary?: string;
  errors?: any;
  contentDeclarationType: EPDContentDeclarationProductType;
}> = ({ epdVersionId, serviceIncludesProductInSystemBoundary, errors, contentDeclarationType }) => {
  const isReadOnly = useIsReadOnlyMode();

  const rows = useEpdProductComponents(epdVersionId!, contentDeclarationType).data;
  const createMutation = useCreateEpdProductComponent(epdVersionId, contentDeclarationType);
  const updateMutation = useUpdateEpdProductComponent(epdVersionId, contentDeclarationType);
  const deleteMutation = useDeleteProductComponents(epdVersionId, contentDeclarationType);

  const { dataTableProps, checkboxColumnNode, handleDeleteSelected, isDeleteDisabled } =
    useGridRowSelectionViaCheckbox<ProductComponent>(isReadOnly, deleteMutation);

  const epdDefinitionInformation = useEPDDefinitionInformation(epdVersionId).data;
  const selectedStandards = epdDefinitionInformation?.declaredStandards?.split(',');
  const isISO21930StandardSelected = selectedStandards?.some((x) => x === ISO_21930_ID);

  const rowClassName = () => ({ 'p-disabled': isReadOnly });

  const handleAddRow = () => {
    // initially add default row in case the table is empty and has default empty row
    if (!rows?.length) {
      createMutation.mutate({ epdVersionId: epdVersionId, weight: 0, declareContentType: contentDeclarationType });
    }
    createMutation.mutate({ epdVersionId: epdVersionId, weight: 0, declareContentType: contentDeclarationType });
  };

  const onCellEditComplete = (e: ColumnEvent) => onCellEdit(e, updateMutation);

  const placeholderCellTemplate = (placeholder: string, value: ProductComponent, options: ColumnBodyOptions) =>
    cellTemplateWithPlaceholder((value as any)?.[options?.field], placeholder);

  const isTableFilledIn = rows?.every((r) => r.weight) && rows?.every((r) => r.name);

  const getIsTableFilledIn = () => {
    if (
      serviceIncludesProductInSystemBoundary === YES_NO.NO ||
      contentDeclarationType != EPDContentDeclarationProductType.Average
    ) {
      return true;
    }
    if (serviceIncludesProductInSystemBoundary === YES_NO.YES) {
      return rows?.some(() => true) && isTableFilledIn;
    }
    return isTableFilledIn;
  };

  const header = (
    <DynamicGridHeader
      caption="Product content"
      error={
        errors?.general ||
        (!getIsTableFilledIn() && 'Required') ||
        (errors && Object.keys(errors).length > 0 && 'Has errors')
      }
      hasValue={getIsTableFilledIn()}
      onAddRow={handleAddRow}
      onDeleteRows={handleDeleteSelected}
      deleteRowsDisabled={isDeleteDisabled}
      required
    />
  );

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column className="p-row-total" />
        <Column className="p-row-total" footer="Total" />
        <Column className="p-row-total" footer={cellTotalTemplate('weight', rows, errors)} />
        <Column className="p-row-total" footer={cellTotalTemplate('finalWeightPercent', rows, errors)} />
        <Column className="p-row-total" footer={cellTotalTemplate('biogenicWeightPercent', rows, errors)} />
        <Column className="p-row-total" footer={cellTotalTemplate('biogenicCarbonWeight', rows, errors)} />
        {isISO21930StandardSelected && (
          <Column className="p-row-total" footer={cellTotalTemplate('biogenicCO2Weight', rows, errors)} />
        )}
      </Row>
      <Row style={{ borderTop: 'none' }}>
        <Column className="p-row-note" />
        <Column
          className="p-row-note"
          footer={
            <div>
              Note<sup>1</sup>
            </div>
          }
        />
        <Column
          className="p-row-note"
          footer={'1 kg biogenic carbon is equivalent to 44/12 kg of CO2'}
          colSpan={isISO21930StandardSelected ? 5 : 4}
        />
      </Row>
      <Row style={{ borderTop: 'none' }}>
        <Column className="p-row-note" />
        <Column
          className="p-row-note"
          footer={
            <div>
              Note<sup>2</sup>
            </div>
          }
        />
        <Column
          className="p-row-note"
          footer={
            <div>
              The value will be changed accordingly to the selected unit in Step 3:
              <br /> Option 1: if choosing declared unit - Biogenic material<sup>1</sup>, <b>kg C/declared unit</b>
              <br /> Option 2: if choosing functional unit - Biogenic material<sup>2</sup>, <b>kg C/product</b>
            </div>
          }
          colSpan={isISO21930StandardSelected ? 5 : 4}
        />
      </Row>
    </ColumnGroup>
  );

  const gridData = rows && rows.length > 0 ? rows : [{ ord: 1, id: null, epdVersionId: epdVersionId }];

  return (
    <DataTableStyled
      header={header}
      footerColumnGroup={footerGroup}
      value={gridData}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      editMode="cell"
      rowClassName={rowClassName}
      {...dataTableProps}
    >
      {checkboxColumnNode}
      <Column
        field="name"
        header="Content name"
        editor={isReadOnly ? undefined : cellTextEditor}
        onCellEditComplete={onCellEditComplete}
        body={(data, options) => placeholderCellTemplate('Type name', data, options)}
      />
      <Column
        field="weight"
        header="Weight, kg"
        editor={isReadOnly ? undefined : cellNumberEditor}
        onCellEditComplete={onCellEditComplete}
        body={(data, options) => placeholderCellTemplate('Type weight', data, options)}
      />
      <Column
        field="finalWeightPercent"
        header="Post-consumer recycled material, weight-% of product"
        editor={isReadOnly ? undefined : cellNumberEditor}
        onCellEditComplete={onCellEditComplete}
        body={(data, options) => placeholderCellTemplate('Type weight', data, options)}
      />
      <Column
        field="biogenicWeightPercent"
        header="Biogenic material, weight-% of product"
        editor={isReadOnly ? undefined : cellNumberEditor}
        onCellEditComplete={onCellEditComplete}
        body={(data, options) => placeholderCellTemplate('Type weight', data, options)}
      />
      <Column
        field="biogenicCarbonWeight"
        header={
          <span>
            Biogenic material<sup>1</sup>, kg C/declared unit or product<sup>2</sup>
          </span>
        }
        editor={isReadOnly ? undefined : cellNumberEditor}
        onCellEditComplete={onCellEditComplete}
        body={(data, options) => placeholderCellTemplate('Type kg C/kg', data, options)}
      />
      {isISO21930StandardSelected && (
        <Column
          field="biogenicCO2Weight"
          header={
            <span>
              Biogenic material kg CO<sup>2</sup>, eq./declared unit or product<sup>2</sup>
            </span>
          }
          body={(data, options) => placeholderCellTemplate('kg CO²/kg', data, options)}
        />
      )}
    </DataTableStyled>
  );
};

export default ProductComponentsGrid;
