import { TabPanel } from 'primereact/tabview';
import React, { useState } from 'react';
import { EPDModel } from 'services/EpdClient';
import { TabViewStyled } from 'styles/v2/Styles.styled';

import AdditionalInformationTab from './AdditionalInformationTab';
import DefinitionTab from './DefinitionTab';
import OrganizationTab from './OrganizationTab';
import PcrLcaAndVerificationTab from './PcrLcaAndVerificationTab';

const Step1: React.FunctionComponent<{
  epd: EPDModel;
  validationState: any;
}> = ({ epd, validationState }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <TabViewStyled activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
      <TabPanel header="EPD definition">
        <DefinitionTab
          epdId={epd?.id!}
          epdVersionId={epd?.versionId!}
          validationState={validationState}
          versionState={epd?.versionState}
        />
      </TabPanel>
      <TabPanel header="PCR, LCA, EPD verification">
        <PcrLcaAndVerificationTab epd={epd} epdVersionId={epd?.versionId!} validationState={validationState} />
      </TabPanel>
      <TabPanel header="Organization information">
        <OrganizationTab
          epdId={epd?.id!}
          epdVersionId={epd?.versionId!}
          canUpload={false}
          isReadOnly={false}
          validationState={validationState}
        />
      </TabPanel>
      <TabPanel header="Additional information">
        <AdditionalInformationTab epd={epd} epdVersionId={epd?.versionId!} isReadOnly={false} />
      </TabPanel>
    </TabViewStyled>
  );
};

export default Step1;
