import DebugOnly from 'util/dev-tools/DebugOnly';
import RenderCounter from 'util/dev-tools/RenderCounter';

import { useIsFetching } from '@tanstack/react-query';

import { PDF_PAGE } from './Styles.styled';

const BackCoverPage = () => {
  const isFetching = useIsFetching();

  return (
    <>
      <DebugOnly>
        <RenderCounter componentName="PDF BackCover" threshold={10} />
      </DebugOnly>
      {/* When all fetches are complete the final page is rendered which is the flag for puppeteer to start pdf conversion */}
      {!isFetching && (
        <PDF_PAGE className="final">
          <div />
          <div style={{ textAlign: 'center', padding: '1cm' }}>environdec.com</div>
        </PDF_PAGE>
      )}
    </>
  );
};

export default BackCoverPage;
