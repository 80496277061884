import { useEpdLcaCustomResultGridData } from 'services/api/queries';

import { LcaResultsGridColumns } from '../LcaResultsGridColumns';
import LcaCustomFixedResultsGrid from './LcaCustomFixedResultsGrid';

type TProps = {
  customResultId: string;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};

const LcaCustomBiogenicResultsGrid = ({ customResultId, disabled, preferEnabledAppearanceEvenWhenDisabled }: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description: 'GWP-biogenic = Global Warming Potential biogenic.',
    },
  ];

  const rows = useEpdLcaCustomResultGridData(customResultId).data;
  return (
    <LcaCustomFixedResultsGrid
      customResultId={customResultId}
      value={rows}
      columns={LcaResultsGridColumns}
      notes={notes}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaCustomBiogenicResultsGrid;
