import { Button } from 'primereact/button';
import styled from 'styled-components';

type TProps = {
  onValidate: any;
};

const ValidateErrorsContainer = ({ onValidate }: TProps) => {
  return (
    <StyledValidateErrorsContainer>
      <StyledValidateErrorsMessageContainer>
        You have several validation errors in the table.
        <br />
        To validate the errors please click the button.
      </StyledValidateErrorsMessageContainer>
      <StyledValidateErrorsButton label="Validate errors" onClick={onValidate} />
    </StyledValidateErrorsContainer>
  );
};

const StyledValidateErrorsContainer = styled.div`
  width: 100%;
  background: #f6e5e4;
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledValidateErrorsMessageContainer = styled.div`
  color: #cc302b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400px;
  line-height: 20px;
`;

const StyledValidateErrorsButton = styled(Button)`
  background-color: '#CC302B';
  border-color: '#CC302B';
  min-width: 90px;
  border-radius: 4px;
  font-size: 1rem;

  &:disabled {
    background-color: ${(props) => props.theme.colors.elementsButtonDisabled};
    border-color: ${(props) => props.theme.colors.elementsButtonDisabled};
  }

  height: 1.5rem;
  min-height: 22px;
  ${(props) => props.theme.typography.buttonButtonDefault};
`;

export default ValidateErrorsContainer;
