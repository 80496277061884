import { LcaResultModel, ProductComponent } from 'types/types';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QUERY_KEYS } from './constants';
import {
  createEpdLcaResultAdditional,
  createLcaCustomResult,
  createLcaCustomResultGridDataRow,
  deleteEpdLcaResultAdditional,
  deleteEpdLcaResultsAdditional,
  deleteLcaCustomResult,
  deleteLcaCustomResultGridDataRow,
  deleteLcaCustomResultGridDataRows,
  updateEpdLcaResultAdditional,
  updateLcaCustomResult,
  updateLcaCustomResultGridDataRow,
  upsertEpdLcaResult,
} from './epdLcaResultsApi';
import { optimisticMutation, useGenericMutation } from './mutations';

export function isOfType<T>(value: any): value is T {
  return typeof value === 'object' && value !== null;
}

export const useUpsertEpdLcaResult = () => {
  return optimisticMutation((data: LcaResultModel) => upsertEpdLcaResult(data), [QUERY_KEYS.EPD_LCA_RESULTS]);
};

// LCA Results additional
export const useCreateEpdLcaResultAdditional = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: LcaResultModel) => createEpdLcaResultAdditional(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_LCA_RESULTS_ADDITIONAL] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        // await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpdateEpdLcaResultAdditional = (epdVersionId: string) => {
  return optimisticMutation(
    (data: LcaResultModel) => updateEpdLcaResultAdditional(data),
    [QUERY_KEYS.EPD_LCA_RESULTS_ADDITIONAL, epdVersionId]
  );
};

export const useDeleteEpdLcaResultsAdditional = () => {
  return useGenericMutation((ids: string[]) => deleteEpdLcaResultsAdditional(ids), [QUERY_KEYS.EPD_LCA_RESULTS_ADDITIONAL]);
};

export const useDeleteEpdLcaResultAdditional = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteEpdLcaResultAdditional(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_LCA_RESULTS_ADDITIONAL] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] }); /// These two are overusing webApi states and validation should be merged
        // await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

// LCA custom results
export const useCreateLcaCustomResult = () => {
  return useGenericMutation((data: any) => createLcaCustomResult(data), [[QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS]]);
};

export const useDeleteLcaCustomResult = () => {
  return useGenericMutation((id: string) => deleteLcaCustomResult(id), [[QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS]]);
};

export const useUpdateLcaCustomResult = (epdVersionId: string) => {
  return useGenericMutation(
    (data: any) => updateLcaCustomResult(data),
    [[QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS, epdVersionId], [QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS]]
  );
};

// LCA custom results grid data
export const useCreateLcaCustomResultGridDataRow = (customResultId: string) => {
  return useGenericMutation(
    (data: any) => createLcaCustomResultGridDataRow(data),
    [[QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS_DATA, customResultId]]
  );
};

export const useDeleteLcaCustomResultGridDataRow = (customResultId: string) => {
  return useGenericMutation(
    (id: string) => deleteLcaCustomResultGridDataRow(id),
    [[QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS_DATA, customResultId]]
  );
};

export const useDeleteLcaCustomResultGridDataRows = (epdVersionId: string, customResultId: string) => {
  return useGenericMutation(
    (ids: string[]) => deleteLcaCustomResultGridDataRows(epdVersionId, ids),
    [[QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS_DATA, customResultId]]
  );
};

export const useUpdateLcaCustomResultGridDataRow = (customResultId: string) => {
  return optimisticMutation(
    (data: ProductComponent) => updateLcaCustomResultGridDataRow(data),
    [QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS_DATA, customResultId]
  );
};
