import ErrorFallback from 'components/error-fallback/ErrorFallback';
import { EditDataIcon } from 'components/v2/icons';
import { Column, ColumnEvent, ColumnProps } from 'primereact/column';
import { DataTableValueArray } from 'primereact/datatable';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  useCreateCpcrSpecificTableRow,
  useDeleteCpcrSpecificTableRows,
  useUpdateCpcrSpecificTableColumn,
  useUpdateCpcrSpecificTableRow,
} from 'services/api/mutations';
import styled from 'styled-components';

import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';
import DynamicGridHeader from '../DynamicGridHeader';
import { cellTextEditor } from '../GridCellEditors';
import { ColumnHeaderInput, DataTableStyled, Placeholder } from '../StyledGrid';
import useGridRowSelectionViaCheckbox from '../useGridRowSelectionViaCheckbox';

interface ColumnPropsExtended extends ColumnProps {
  isDataReadonly?: boolean;
  isTitleReadonly?: boolean;
  placeholder?: string;
}

type TProps = {
  epdVersionId: string;
  tableTitle?: string;
  tableCode: string;
  value?: DataTableValueArray | undefined;
  addDefaultEmptyRow?: boolean;
  columns?: ColumnPropsExtended[] | undefined;
  notes?: { note: React.ReactNode | string; description: React.ReactNode | string }[] | undefined;
  errors: any;
};

const DynamicGrid = ({ epdVersionId, tableTitle, tableCode, value, addDefaultEmptyRow, columns, notes, errors }: TProps) => {
  const isReadOnly = useIsReadOnlyMode();
  const createMutation = useCreateCpcrSpecificTableRow();
  const updateMutation = useUpdateCpcrSpecificTableRow(epdVersionId);
  const updateColumnHeaderMutation = useUpdateCpcrSpecificTableColumn(epdVersionId);
  const deleteMutation = useDeleteCpcrSpecificTableRows();

  const { dataTableProps, checkboxColumnNode, handleDeleteSelected, isDeleteDisabled } = useGridRowSelectionViaCheckbox<any>(
    isReadOnly,
    deleteMutation
  );

  const rowClassName = (x: any) => ({ 'p-disabled': isReadOnly || x.isDataReadonly });

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;
    updateMutation.mutate(rowData);
  };

  const onHeaderEdit = (e: any, column: any) => {
    updateColumnHeaderMutation.mutate({ id: column.id, header: e.target.value });
  };

  const columnHeaderTemplate = (column: any, index: any) => {
    return column.isHeaderReadonly ? (
      column.header
    ) : (
      <div style={{ display: 'grid', gridTemplateColumns: 'auto  1rem', columnGap: '0.5rem', minWidth: '8rem' }}>
        <div>
          <ColumnHeaderInput
            type="text"
            defaultValue={column.header}
            onBlur={(e) => onHeaderEdit(e, column)}
            disabled={isReadOnly}
          />
        </div>
        {!isReadOnly && (
          <div>
            <EditDataIcon />
          </div>
        )}
      </div>
    );
  };

  const handleAddRow = () => {
    // initially add default row in case the table is empty and has default empty row
    if (addDefaultEmptyRow && !value?.length) {
      createMutation.mutate({ epdVersionId, tableCode });
    }

    createMutation.mutate({ epdVersionId, tableCode });
  };

  const header = (
    <DynamicGridHeader
      caption={tableTitle}
      onAddRow={handleAddRow}
      onDeleteRows={handleDeleteSelected}
      deleteRowsDisabled={isDeleteDisabled}
      error={errors?.general || (errors && Object.keys(errors).length > 0 && 'Has errors')}
    />
  );

  const renderCellBody = (row: any, options: any, placeholder?: string) => {
    return (row as any)?.[options?.field] || <Placeholder>{placeholder || 'Type here'}</Placeholder>;
  };

  const gridData =
    value && value.length > 0 ? value : addDefaultEmptyRow ? [{ ord: 1, id: null, tableCode: tableCode }] : [];

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DataTableStyledHeader
        value={gridData}
        dataKey="id"
        tableStyle={{ minWidth: '49rem' }}
        showGridlines
        editMode="cell"
        header={header}
        isDataSelectable={() => !isReadOnly}
        rowClassName={rowClassName}
        {...dataTableProps}
      >
        {columns && columns.length > 0 && checkboxColumnNode}
        {columns?.map((x, index) => (
          <Column
            key={x.field}
            {...x}
            onCellEditComplete={onCellEditComplete}
            header={columnHeaderTemplate(x, index)}
            editor={x.isDataReadonly || isReadOnly ? undefined : (options) => cellTextEditor(options)}
            body={(row, options) => renderCellBody(row, options, x.placeholder)}
          />
        ))}
      </DataTableStyledHeader>
    </ErrorBoundary>
  );
};

const DataTableStyledHeader = styled(DataTableStyled)`
  &.p-datatable .p-column-header-content {
    display: block;
  }
`;

export default DynamicGrid;
