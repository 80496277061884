import { editAdminSelectStyles } from 'components/form/EditAdminSelectStyles';
import LargeRadiusTextInput from 'components/form/LargeRadiusTextInput';
import SortedTable from 'components/form/SortedTable';
import { Formik } from 'formik';
import errorIcon from 'images/icons/svg/error.svg';
import helpIcon from 'images/icons/svg/info.svg';
import successIcon from 'images/icons/svg/success.svg';
import { drop, isEmpty, range } from 'lodash';
import { FixedLabel } from 'pages/wizard/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import CompanyService from 'services/CompanyService';
import { CompanyModel, InvoiceModel2, InvoiceStatus } from 'services/EpdClient';
import AdminInvoicesService from 'services/admin/AdminInvoicesService';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import {
  AdminBox,
  AdminSearchGridForm,
  ButtonSmall,
  Container,
  EditFull,
  EditInfoBox,
  ErrorBox,
  ErrorMessage,
  H2,
  InfoBox,
  LabelBox,
  Message,
  MessageBox,
  ResultMessageBox,
  SearchBox,
  StyledReactSelect,
  ValueBox,
} from 'styles/Styles.styled';
import Spinner from 'styles/spinner-left.styled';
import { Theme } from 'styles/theme';
import { Option } from 'types/types';
import { PoorMansError, formatDate } from 'util/utils';
import * as Yup from 'yup';

import { SortComparator } from '../utils';

const AdminInvoices: React.FunctionComponent = () => {
  const history = useHistory();
  const theme = useTheme() as Theme;

  const getItemInfo = (item: any) => {
    history.push(EpdLinks.editInvoice(item.id));
  };

  const doSearch = (rawValues: any) => {
    let values = {
      ...rawValues,
    };

    if (values?.status) {
      values.status = values.status.map((res: any) => res.value);
    }

    if (values?.period) {
      values.period = values.period.map((res: any) => res.value);
    }

    values = {
      ...rawValues,
      ...values.status,
      ...values.period,
    };

    const getInvoices = async () => {
      let statusValues;
      let periodValues;

      if (!isEmpty(values?.status)) {
        statusValues = values.status.map((status: any) => status.value);
      }

      if (!isEmpty(values?.period)) {
        periodValues = values.period.map((period: any) => period.value);
      }

      let allInvoices: any[] = await AdminInvoicesService.getInvoices(
        values.invoiceNumber,
        values.name,
        values.company?.value,
        periodValues,
        statusValues
      );

      allInvoices = allInvoices.map((invoice) => {
        const statusElementStyle = () => {
          switch (invoice.invoiceStatus) {
            case 'Sent':
              return {
                margin: '0 10px 0 0',
                padding: '5px 2px',
                color: 'white',
                backgroundColor: `${theme.colors.lightOrange}`,
                borderRadius: '3px',
                textAlign: 'center',
              };
            case 'Approved':
              return {
                margin: '0 10px 0 0',
                padding: '5px 2px',
                color: 'white',
                backgroundColor: `${theme.colors.regionColorLight}`,
                borderRadius: '3px',
                textAlign: 'center',
              };
            case 'Credited':
              return {
                margin: '0 10px 0 0',
                padding: '5px 2px',
                color: 'white',
                backgroundColor: `${theme.colors.darkKhaki}`,
                borderRadius: '3px',
                textAlign: 'center',
              };
            case 'Paid':
              return {
                margin: '0 10px 0 0',
                padding: '5px 2px',
                color: 'white',
                backgroundColor: `${theme.colors.regionColor}`,
                borderRadius: '3px',
                textAlign: 'center',
              };
            case 'Declined':
              return {
                margin: '0 10px 0 0',
                padding: '5px 2px',
                color: 'white',
                backgroundColor: `${theme.colors.darkGray}`,
                borderRadius: '3px',
                textAlign: 'center',
              };
            case 'Quotation':
              return {
                margin: '0 10px 0 0',
                padding: '5px 2px',
                color: 'white',
                backgroundColor: `${theme.colors.shadedRed}`,
                borderRadius: '3px',
                textAlign: 'center',
              }; //#EF271B
          }
        };
        const statusElement = () => {
          return <div style={statusElementStyle() as any}>{invoice.invoiceStatus}</div>;
        };
        return { ...invoice, dueDate: formatDate(invoice.dueDate), invoiceStatus: statusElement() };
      });
      setSelectedInvoices(allInvoices);
      setInvoicesNumber(allInvoices.length);
      setUnderSearch(true);
    };
    getInvoices();
  };

  const onSearch = (rawValues: any) => {
    doSearch(rawValues);
    localStorage.setItem('invoiceValues', JSON.stringify(rawValues));
  };

  const titles = [
    { label: 'Status', field: 'invoiceStatus', fieldStyle: { textAlign: 'right' } },
    { label: 'Organization', field: 'companyName' },
    { label: 'Period', field: 'period' },
    { label: 'Due date', field: 'dueDate', sortComparator: SortComparator },
    {
      label: 'Days late',
      field: 'lateDays',
      fieldStyle: { color: '#E65F5C', textAlign: 'center' },
      sortComparator: SortComparator,
      tooltip: Tooltips.daysLate,
    },
  ];
  const startYear = 2021;

  const [companies, setCompanies] = useState<CompanyModel[]>([]);
  const [invoiceStatus, setInvoiceStatus] = useState<[]>([]);
  const [periods, setPeriods] = useState<string[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<InvoiceModel2[]>([]);
  const [underSearch, setUnderSearch] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<object | null>(null);
  const [statusAllInvoices, setStatusAllInvoices] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [invoicesNumber, setInvoicesNumber] = useState<number | undefined>();

  const validationSchema = Yup.object({
    period: Yup.string(),
    status: Yup.string(),
    company: Yup.object().nullable(),
    name: Yup.string().nullable(),
    invoiceNumber: Yup.string().nullable(),
  });

  const getPeriods = function () {
    // range and drop from lodash
    const currentYear = new Date().getFullYear();
    const currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3);
    const numberOfFutureQuarters = 4 - currentQuarter;
    // Create the list of quarters on the format ####-Q#, in descending order
    const quarters = range(startYear, currentYear + 1)
      .flatMap((y) => range(0, 4).map((q) => `${y} Q${q + 1}`))
      .reverse();

    const annualFee = range(startYear, currentYear + 1)
      .map((y) => `${y} Annual fee`)
      .reverse();
    // Skip the future quarters from the result
    return [...drop(quarters, numberOfFutureQuarters)].concat(annualFee).sort((a, b) => b.localeCompare(a));
  };

  const updateInvoicesStatuses = async () => {
    try {
      await AdminInvoicesService.updateInvoiceStatuses();
      setStatusAllInvoices(true);
    } catch (error) {
      setErrorMessage(PoorMansError(error));
    }
  };

  useEffect(() => {
    const getInvoiceSummary = async () => {
      const result = await CompanyService.getCompanies();
      setCompanies(result);
      const items = Object.values(InvoiceStatus as any);
      setInvoiceStatus(items as []);
      setPeriods(getPeriods());
      const previousInvoiceValues = localStorage.getItem('invoiceValues');
      if (previousInvoiceValues) {
        const values = JSON.parse(previousInvoiceValues as string);
        setInitialValues(values);
        doSearch(values);
        localStorage.removeItem('invoiceValues');
      } else {
        setInitialValues({
          period: '',
          status: '',
          company: {},
          name: '',
          invoiceNumber: '',
        });
      }
    };
    getInvoiceSummary();
  }, []);

  if (!initialValues) {
    return (
      <div style={{ marginTop: '8rem' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <EditFull>
        <H2>Invoices</H2>
        {!statusAllInvoices && (
          <div style={{ gridColumnStart: '1', gridColumnEnd: '2', margin: '1rem 0' }}>
            <EditInfoBox>
              <img src={helpIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
              <Flex>
                To synchronize the statuses of all invoices click the following button
                <ButtonSmall
                  style={{ margin: '0 1rem', padding: '0.5rem', borderRadius: '3px' }}
                  onClick={() => updateInvoicesStatuses()}
                >
                  Update statuses of all invoices
                </ButtonSmall>
              </Flex>
            </EditInfoBox>
          </div>
        )}
        {statusAllInvoices && (
          <div style={{ gridColumnStart: '1', gridColumnEnd: '2', margin: '1rem 0' }}>
            <InfoBox>
              <GreenIcon src={successIcon} alt="information" />
              <div style={{ margin: '1rem 0', color: 'green' }}>The statuses of all invoices are updated successfully.</div>
            </InfoBox>
          </div>
        )}
        {errorMessage && (
          <div style={{ gridColumnStart: '1', gridColumnEnd: '2', margin: '1rem 0' }}>
            <ErrorBox>
              <RedIcon src={errorIcon} alt="information" />
              <div style={{ margin: '1rem 0', color: 'red' }}>Failed to update the statuses of all invoices.</div>
            </ErrorBox>
          </div>
        )}

        <AdminBox>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSearch}>
            <AdminSearchGridForm>
              <LabelBox>
                <FixedLabel>Period</FixedLabel>
              </LabelBox>
              <PeriodValue>
                <StyledReactSelect
                  name="period"
                  styles={editAdminSelectStyles}
                  options={periods.map((x: string) => {
                    return { value: x, label: x } as Option;
                  })}
                  isMulti={true}
                  isClearable={true}
                  defaultValue={periods}
                ></StyledReactSelect>
              </PeriodValue>

              <StatusText>
                <FixedLabel>Status</FixedLabel>
              </StatusText>
              <StatusValue>
                <StyledReactSelect
                  name="status"
                  styles={editAdminSelectStyles}
                  options={invoiceStatus.map((x: string) => {
                    return { value: x, label: x } as Option;
                  })}
                  isMulti={true}
                  isClearable={true}
                  defaultValue={invoiceStatus}
                ></StyledReactSelect>
              </StatusValue>

              <LabelBox>
                <FixedLabel>Organization</FixedLabel>
              </LabelBox>
              <ValueBox>
                <StyledReactSelect
                  name="company"
                  styles={editAdminSelectStyles}
                  options={companies.map((x: any) => {
                    return { value: x.id, label: x.name } as Option;
                  })}
                  isMulti={false}
                  isClearable={true}
                  defaultValue={companies}
                ></StyledReactSelect>
              </ValueBox>

              <LabelBox>
                <FixedLabel>Name</FixedLabel>
              </LabelBox>
              <ValueBox>
                <LargeRadiusTextInput name="name" placeholder="Search by organization name..." />
              </ValueBox>

              <LabelBox>
                <FixedLabel>Invoice number</FixedLabel>
              </LabelBox>
              <ValueBox>
                <LargeRadiusTextInput name="invoiceNumber" placeholder="Search by invoice number..." />
              </ValueBox>

              <SearchBox>
                <ButtonSmall style={{ padding: '0.5rem 1rem', borderRadius: '3px' }} type="submit">
                  Search
                </ButtonSmall>
              </SearchBox>
            </AdminSearchGridForm>
          </Formik>
        </AdminBox>

        {underSearch && !selectedInvoices && (
          <div style={{ marginTop: '1rem' }}>
            <Spinner />
          </div>
        )}
        {underSearch && isEmpty(selectedInvoices) && (
          <ErrorMessage style={{ marginTop: '1rem', color: 'black' }}>No records found.</ErrorMessage>
        )}
        {underSearch && !isEmpty(selectedInvoices) && (
          <MessageBox>
            <ResultMessageBox>
              {invoicesNumber === 1 ? (
                <Message style={{ marginLeft: 0 }}>Found {invoicesNumber} match</Message>
              ) : (
                <Message>Found {invoicesNumber} matches</Message>
              )}
            </ResultMessageBox>
            <div
              style={{
                display: 'inline-block',
                gridColumnStart: '2',
                gridColumnEnd: '3',
                minWidth: '100%',
                padding: '1px',
                borderBottomLeftRadius: '3px',
                borderBottom: `1px solid ${theme.colors.mediumGrey}`,
                alignSelf: 'end',
                justifySelf: 'end',
              }}
            ></div>
          </MessageBox>
        )}
        {underSearch && !isEmpty(selectedInvoices) && (
          <div style={{ marginTop: '-1.2rem' }}>
            <SortedTable columns={titles} collection={selectedInvoices ?? []} onClick={getItemInfo} />
          </div>
        )}
      </EditFull>
    </Container>
  );
};

const Tooltips = {
  daysLate:
    'Days late displays the number of days since Due date. If Due date is missing it displays the number of days since the invoice was created.',
};

const PeriodValue = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 5;
  }
`;

const StatusText = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 5;
    grid-column-end: 6;
  }
`;

const StatusValue = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 6;
    grid-column-end: 8;
  }
`;

const Flex = styled.div`
  display: grid;
  grid-template-rows: 2;
  row-gap: 10px;
  align-items: center;

  ${(props) => props.theme.media.tablet} {
    grid-template-columns: auto auto;
    column-gap: 5px;
  }
`;

const GreenIcon = styled.img`
  height: 35px;
  margin-right: 1rem;
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(65%) contrast(100%);
`;

const RedIcon = styled(GreenIcon)`
  filter: invert(12%) sepia(96%) saturate(6728%) hue-rotate(359deg) brightness(94%) contrast(115%);
`;

export default AdminInvoices;
