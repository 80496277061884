import { TabPanel } from 'primereact/tabview';
import React, { useState } from 'react';
import { EPDModel, FileLoadingModel } from 'services/EpdClient';
import { TabViewStyled } from 'styles/v2/Styles.styled';

import LcaResultsTab from './LcaResultsTab';
import ScenariosTab from './ScenariosTab';
import SpecificationTab from './SpecificationTab';
import SystemBoundaryTab from './SystemBoundaryTab';

const Step3: React.FunctionComponent<{
  epd?: EPDModel;
  validationState: any;
  onChangeEpd: any;
  fileLoading?: FileLoadingModel[];
}> = ({ epd, validationState, onChangeEpd, fileLoading }) => {
  const [isCore15804ValidateBtnPressed, setIsCore15804ValidateBtnPressed] = useState(false);
  const [isAdditionalMandatoryValidateBtnPressed, setIsAdditionalMandatoryValidateBtnPressed] = useState(false);
  const [isAdditionalVoluntary15804ValidateBtnPressed, setIsAdditionalVoluntary15804ValidateBtnPressed] = useState(false);
  const [isAdditionalVoluntaryValidateBtnPressed, setIsAdditionalVoluntaryValidateBtnPressed] = useState(false);
  const [isResource15804ValidateBtnPressed, setIsResource15804ValidateBtnPressed] = useState(false);
  const [isWaste15804ValidateBtnPressed, setIsWaste15804ValidateBtnPressed] = useState(false);
  const [isOutputFlow15804ValidateBtnPressed, setIsOutputFlow15804ValidateBtnPressed] = useState(false);

  return (
    <TabViewStyled>
      <TabPanel header="Specification">
        <SpecificationTab epdId={epd?.id!} epdVersionId={epd?.versionId!} validationState={validationState} />
      </TabPanel>
      <TabPanel header="System boundary">
        <SystemBoundaryTab
          epdId={epd?.id!}
          epdVersionId={epd?.versionId!}
          validationState={validationState}
          onChangeEpd={onChangeEpd}
          fileLoading={fileLoading}
          canUpload={true}
        />
      </TabPanel>
      <TabPanel header="Scenarios">
        <ScenariosTab epdVersionId={epd?.versionId!} validationState={validationState} />
      </TabPanel>
      <TabPanel header="Results">
        <LcaResultsTab
          epdId={epd?.id!}
          epdVersionId={epd?.versionId!}
          errors={validationState?.errors?.lcaResults}
          isCore15804ValidateBtnPressed={isCore15804ValidateBtnPressed}
          setIsCore15804ValidateBtnPressed={setIsCore15804ValidateBtnPressed}
          isAdditionalMandatoryValidateBtnPressed={isAdditionalMandatoryValidateBtnPressed}
          setIsAdditionalMandatoryValidateBtnPressed={setIsAdditionalMandatoryValidateBtnPressed}
          isAdditionalVoluntary15804ValidateBtnPressed={isAdditionalVoluntary15804ValidateBtnPressed}
          setIsAdditionalVoluntary15804ValidateBtnPressed={setIsAdditionalVoluntary15804ValidateBtnPressed}
          isAdditionalVoluntaryValidateBtnPressed={isAdditionalVoluntaryValidateBtnPressed}
          setIsAdditionalVoluntaryValidateBtnPressed={setIsAdditionalVoluntaryValidateBtnPressed}
          isResource15804ValidateBtnPressed={isResource15804ValidateBtnPressed}
          setIsResource15804ValidateBtnPressed={setIsResource15804ValidateBtnPressed}
          isWaste15804ValidateBtnPressed={isWaste15804ValidateBtnPressed}
          setIsWaste15804ValidateBtnPressed={setIsWaste15804ValidateBtnPressed}
          isOutputFlow15804ValidateBtnPressed={isOutputFlow15804ValidateBtnPressed}
          setIsOutputFlow15804ValidateBtnPressed={setIsOutputFlow15804ValidateBtnPressed}
        />
      </TabPanel>
    </TabViewStyled>
  );
};

export default Step3;
