import { pcrSelectStyles } from 'components/form/PcrSelect';
import UploadFile, { docxOrPdfType } from 'components/form/UploadFile';
import Widget from 'components/form/Widget';
import HelpIcon from 'components/icons/HelpIcon';
import { isEmpty } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import ValueType from 'react-select';
import CpcService from 'services/CpcService';
import {
  ActionState,
  CPCLevel,
  CPCModel,
  GeographicalScopeModel,
  ProductCategoryModel,
  ProgramOperatorModel,
} from 'services/EpdClient';
import PcrService from 'services/PcrService';
import styled, { css } from 'styled-components';
import { H3, Input, Label } from 'styles/Styles.styled';
import { Option } from 'types/types';
import { formatDate } from 'util/utils';

import { FixedLabel, FixedTextAreaComponent, Row } from './PcrWizard';

const WizardStep1: React.FunctionComponent<{
  pcr?: any;
  onChangePcr?: any;
  validation: any;
  goToStep: any;
}> = ({ pcr, onChangePcr, validation, goToStep }) => {
  const [categories, setCategories] = useState<ProductCategoryModel[]>([]);
  const [geographicals, setGeographicals] = useState<GeographicalScopeModel[]>([]);
  const [sections, setSections] = useState<CPCModel[]>([]);
  const [divisions, setDivisions] = useState<CPCModel[]>([]);
  const [groups, setGroups] = useState<CPCModel[]>([]);
  const [classes, setClasses] = useState<CPCModel[]>([]);
  const [subClasses, setSubClasses] = useState<CPCModel[]>([]);

  const [selectedSections, setSelectedSections] = useState<CPCModel[]>([]);
  const [selectedDivisions, setSelectedDivisions] = useState<CPCModel[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<CPCModel[]>([]);
  const [selectedClasses, setSelectedClasses] = useState<CPCModel[]>([]);
  const [selectedSubClasses, setSelectedSubClasses] = useState<CPCModel[]>([]);
  const [programOperators, setProgramOperators] = useState<ProgramOperatorModel[]>([]);

  const selectedCategory = pcr?.category && ({ label: pcr.category.name, value: pcr.category.id } as Option);

  // const selectedGeographical =
  //   !isEmpty(pcr?.geographical) && pcr.geographical.map((item: any) => ({ label: item.name, value: item.id }));

  const onChangeCategories = (category: SingleValue<Option>) => {
    if (category && (category as Option).value) {
      onChangePcr('category', { id: (category as Option).value, name: (category as Option).label });
    } else {
      onChangePcr('category', null);
    }
  };

  const onChangeGeographical = (geographical: MultiValue<Option>) => {
    if (!isEmpty(geographical)) {
      // const selectedValues = geographical?.map((item) => ({ id: (item as any).value, name: (item as any).label }));
      //  onChangePcr('geographical', selectedValues);
      // value={selectedGeographical}
    } else {
      //  onChangePcr('geographical', [])
    }
  };

  const onChangeProgramOperators = (programOperator: MultiValue<Option>) => {
    if (!isEmpty(programOperator)) {
      // const selectedValues = programOperator?.map((item) => ({ id: (item as any).value, name: (item as any).label }));
    } else {
    }
  };

  const levels = [
    {
      selectedValues: selectedSections,
      setSelectedValues: setSelectedSections,
      setOptions: setSections,
      level: CPCLevel.Section,
    },
    {
      selectedValues: selectedDivisions,
      setSelectedValues: setSelectedDivisions,
      setOptions: setDivisions,
      level: CPCLevel.Division,
    },
    {
      selectedValues: selectedGroups,
      setSelectedValues: setSelectedGroups,
      setOptions: setGroups,
      level: CPCLevel.Group,
    },
    {
      selectedValues: selectedClasses,
      setSelectedValues: setSelectedClasses,
      setOptions: setClasses,
      level: CPCLevel.Classes,
    },
    {
      selectedValues: selectedSubClasses,
      setSelectedValues: setSelectedSubClasses,
      setOptions: setSubClasses,
      level: CPCLevel.Subclasses,
    },
  ];

  const updateCpcField = async (levelNumber: number, parentValues: string[]) => {
    const currentLevel = levels[levelNumber];
    const result = await CpcService.getCpcs(undefined, currentLevel.level, parentValues);
    currentLevel.setOptions(result);
    const selectedValues = currentLevel.selectedValues.filter((selectedValue) => {
      return result.some((option) => option.code === (selectedValue as any).value);
    });
    currentLevel.setSelectedValues(selectedValues);
    if (levelNumber + 1 < levels.length) {
      updateCpcField(
        levelNumber + 1,
        selectedValues.map((item) => (item as any).value)
      );
    }
  };

  const onChangeCpcField = async (values: MultiValue<any>[], levelNumber: number) => {
    if (!values) {
      for (let index = levelNumber; index < levels.length; index++) {
        levels[index].setSelectedValues([]);
        if (index > levelNumber) {
          levels[index].setOptions([]);
        }
      }
      return;
    }
    const currentLevel = levels[levelNumber];
    currentLevel.setSelectedValues(values as any);
    let selectedValues: any[] = [...values];
    selectedValues = selectedValues.map((item) => item.value);
    updateCpcField(levelNumber + 1, selectedValues);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const result = await PcrService.getAllCategories();
      setCategories(result as any);
    };
    const fetchGeographicals = async () => {
      const result = await PcrService.getGeographicals();
      setGeographicals(result);
    };
    const fetchCpcs = async () => {
      const cpcLevels = [
        { level: CPCLevel.Section, setFunction: setSections },
        { level: CPCLevel.Division, setFunction: setDivisions },
        { level: CPCLevel.Group, setFunction: setGroups },
        { level: CPCLevel.Classes, setFunction: setClasses },
        { level: CPCLevel.Subclasses, setFunction: setSubClasses },
      ];
      for (let cpcLevel of cpcLevels) {
        const result = await CpcService.getCpcs(undefined, cpcLevel.level, undefined);
        cpcLevel.setFunction(result);
      }
    };
    const fetchProgramOperators = async () => {
      const result = await PcrService.getProgramOperators();
      setProgramOperators(result as any);
    };
    fetchCategories();
    fetchGeographicals();
    fetchCpcs();
    fetchProgramOperators();
  }, []);

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];

    if (!file || !pcr) {
      return null;
    }

    onChangePcr && onChangePcr(file, propertyName);
  };

  return (
    <div>
      <H3 style={{ marginTop: 0 }}>Proposed scope</H3>
      <Row>
        <FixedLabel>PCR Name</FixedLabel>
        <FixedInput
          defaultValue={pcr?.name}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('name', e.currentTarget.value)}
        />
      </Row>
      <Row>
        <FixedLabel>Choose product category</FixedLabel>
        <Select
          onChange={(e) => onChangeCategories(e)}
          value={selectedCategory}
          isMulti={false}
          styles={pcrSelectStyles}
          isClearable={true}
          options={categories?.map((category) => {
            return {
              value: category.id,
              label: category.title,
            } as Option;
          })}
        />
      </Row>
      <Row>
        <FixedLabel>Product category definition description</FixedLabel>
        <FixedTextAreaComponent
          value={pcr?.categoryDefinition}
          propertyName={'text'}
          onChangePcr={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('categoryDefinition', e.currentTarget.value)}
          disabled={false}
        />
      </Row>
      <Row>
        <FixedLabel style={{ alignSelf: 'start', paddingTop: '1rem' }}>CPC classification</FixedLabel>
        <Widget />
      </Row>
      <Row>
        <FixedLabel>CPC classification</FixedLabel>
        <Select
          placeholder={'Select sections'}
          onChange={(e) => onChangeCpcField(e as any, 0)}
          isMulti={true}
          styles={pcrSelectStyles}
          isClearable={true}
          options={sections?.map((section) => {
            return {
              value: section.code,
              label: section.description,
            } as Option;
          })}
        />
      </Row>
      <Row>
        <FixedLabel></FixedLabel>
        <Select
          isDisabled={isEmpty(selectedSections)}
          value={selectedDivisions as any}
          placeholder={'Select divisions'}
          onChange={(e) => onChangeCpcField(e as any, 1)}
          isMulti={true}
          styles={pcrSelectStyles}
          isClearable={true}
          options={divisions?.map((division) => {
            return {
              value: division.code,
              label: division.description,
            } as Option;
          })}
        />
      </Row>
      <Row>
        <FixedLabel></FixedLabel>
        <Select
          isDisabled={isEmpty(selectedDivisions)}
          placeholder={'Select groups'}
          value={selectedGroups as any}
          onChange={(e) => onChangeCpcField(e as any, 2)}
          isMulti={true}
          styles={pcrSelectStyles}
          isClearable={true}
          options={groups?.map((group) => {
            return {
              value: group.code,
              label: group.description,
            } as Option;
          })}
        />
      </Row>
      <Row>
        <FixedLabel> </FixedLabel>
        <Select
          isDisabled={isEmpty(selectedGroups)}
          placeholder={'Select classes'}
          value={selectedClasses as any}
          onChange={(e) => onChangeCpcField(e as any, 3)}
          isMulti={true}
          styles={pcrSelectStyles}
          isClearable={true}
          options={classes?.map((x) => {
            return {
              value: x.code,
              label: x.description,
            } as Option;
          })}
        />
      </Row>
      <Row>
        <FixedLabel></FixedLabel>
        <Select
          isDisabled={isEmpty(selectedClasses)}
          placeholder={'Select subclasses'}
          value={selectedSubClasses as any}
          onChange={(e) => onChangeCpcField(e as any, 4)}
          isMulti={true}
          styles={pcrSelectStyles}
          isClearable={true}
          options={subClasses?.map((subClass) => {
            return {
              value: subClass.code,
              label: subClass.description,
            } as Option;
          })}
        />
      </Row>
      <Row>
        <FixedLabel>CPC description</FixedLabel>
        <FixedTextAreaComponent
          value={pcr?.cpcDescription}
          propertyName={'text'}
          onChangePcr={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('cpcDescription', e.currentTarget.value)}
          disabled={false}
        />
      </Row>
      <Row>
        <FixedLabel>Geographical</FixedLabel>
        <Select
          onChange={(e) => onChangeGeographical(e as any)}
          isMulti={true}
          styles={pcrSelectStyles}
          isClearable={true}
          options={geographicals?.map((geographical) => {
            return {
              value: geographical.id,
              label: geographical.name,
            } as Option;
          })}
        />
      </Row>
      <H3 style={{ marginTop: '3rem' }}>
        Have you checked that there is not already a valid PCR or similar documents for this product category?
      </H3>
      <Row style={{ alignItems: 'left' }}>
        <FixedLabel>In the PCR library</FixedLabel>
        <div>
          <input
            type="checkbox"
            style={{ margin: '0.5rem 0' }}
            defaultChecked={pcr?.isInPcrLibrary}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('isInPcrLibrary', e.currentTarget.checked)}
          />
        </div>
      </Row>
      <Row>
        <FixedLabel>In other programs operated in accordance ISO 14025</FixedLabel>
        <Select
          onChange={(e) => onChangeProgramOperators(e as any)}
          isMulti={true}
          styles={pcrSelectStyles}
          isClearable={true}
          options={programOperators?.map((programOperator) => {
            return {
              value: programOperator.id,
              label: programOperator.name,
            } as Option;
          })}
        />
      </Row>
      <Row style={{ alignItems: 'left' }}>
        <FixedLabel>In the European Commission product environmental footprint (PEF) initiative</FixedLabel>
        <div>
          <input
            type="checkbox"
            style={{ margin: '0.5rem 0 1rem 0' }}
            defaultChecked={pcr?.isPEF}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('isPEF', e.currentTarget.checked)}
          />
        </div>
      </Row>
      <Row style={{ alignItems: 'left' }}>
        <FixedLabel>In ongoing standardisation within CEN products TCs, related to EN 15804</FixedLabel>
        <div>
          <input
            type="checkbox"
            style={{ margin: '0.5rem 0 1rem 0' }}
            defaultChecked={pcr?.isEN15804}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('isEN15804', e.currentTarget.checked)}
          />
        </div>
      </Row>
      <Row>
        <FixedLabel>
          Explanation <HelpIcon content={Tooltips.explanation} />
        </FixedLabel>
        <FixedTextAreaComponent
          value={pcr?.explanation}
          propertyName={'text'}
          onChangePcr={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('explanation', e.currentTarget.value)}
          disabled={false}
        />
      </Row>
      <H3 style={{ marginTop: '2rem' }}>Proposed PCR-moderator</H3>
      <Row>
        <FixedLabel>Name</FixedLabel>
        <SubRow>
          <FixedInput
            placeholder="First Name"
            value={pcr?.firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('firstName', e.currentTarget.value)}
          />
          <FixedInput
            placeholder="Last Name"
            value={pcr?.lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('lastName', e.currentTarget.value)}
          />
        </SubRow>
      </Row>
      <Row>
        <FixedLabel>Email</FixedLabel>
        <FixedInput
          value={pcr?.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('email', e.currentTarget.value)}
        />
      </Row>
      <Row>
        <FixedLabel>Organization</FixedLabel>
        <FixedInput
          value={pcr?.organisation}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('organisation', e.currentTarget.value)}
        />
      </Row>
      <Row>
        <FixedLabel>Relevant qualifications</FixedLabel>
        <FixedLabel style={{ marginTop: '1.5rem' }}>Describe and verify LCA and EPD skills</FixedLabel>
      </Row>
      <Row>
        <FixedLabel></FixedLabel>
        <FixedTextAreaComponent
          value={pcr?.lcaSkills}
          propertyName={'lcaSkills'}
          onChangePcr={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('lcaSkills', e.currentTarget.value)}
          disabled={false}
        />
      </Row>
      <Row>
        <FixedLabel></FixedLabel>
        <UploadFile
          accept={docxOrPdfType}
          title="Please upload documents (docx / PDF)"
          uploadFile={uploadFile}
          disabled={false}
          documents={pcr?.qualificationsDocuments}
          onChangeFile={onChangePcr}
          field="PCR-QUALIFICATIONS"
        />
      </Row>
      <Row>
        <FixedLabel></FixedLabel>
        <FixedLabel style={{ marginTop: '1.5rem' }}>Describe and verify recent management skills</FixedLabel>
      </Row>
      <Row>
        <FixedLabel></FixedLabel>
        <FixedTextAreaComponent
          value={pcr?.managementSkills}
          propertyName={'managementSkills'}
          onChangePcr={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('managementSkills', e.currentTarget.value)}
          disabled={false}
        />
      </Row>
      <Row>
        <FixedLabel></FixedLabel>
        <UploadFile
          accept={docxOrPdfType}
          title="Please upload documents (docx / PDF)"
          uploadFile={uploadFile}
          disabled={false}
          documents={pcr?.skillsDocuments}
          onChangeFile={onChangePcr}
          field="PCR-SKILLS"
        />
      </Row>
      <H3 style={{ marginTop: '2rem' }}>PCR committee</H3>
      <Row>
        <FixedLabel>Members</FixedLabel>
        <SubRow>
          <FixedInput
            placeholder="Given Name"
            value={pcr?.firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('givenName', e.currentTarget.value)}
          />
          <FixedInput
            placeholder="Sur Name"
            value={pcr?.lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('surName', e.currentTarget.value)}
          />
        </SubRow>
      </Row>
      <Row>
        <FixedLabel></FixedLabel>
        <FixedInput
          placeholder="Affiliation"
          value={pcr?.affiliation}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('affiliation', e.currentTarget.value)}
        />
      </Row>
      <div style={{ display: 'flex', margin: '1.5rem 0 1rem 0' }}>
        <Label>Does the PCR committee as a whole process competence in:</Label>
      </div>
      <Row>
        <FixedLabel>LCA</FixedLabel>
        <FlexItem>
          <input
            type="checkbox"
            style={{ margin: '0.5rem 0' }}
            defaultChecked={pcr?.inLcaCommitteeExplain}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('inLcaCommittee', e.currentTarget.checked)}
          />
          <FixedInputLca
            placeholder="In what way..."
            style={{ flexGrow: 1 }}
            value={pcr?.inLcaCommitteeExplain}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangePcr('inLcaCommitteeExplain', e.currentTarget.value)
            }
          />
        </FlexItem>
      </Row>
      <Row>
        <FixedLabel>
          The key technologies and processes that contribute to the lifecycle of those products that belong to the product
          category covered by the PCR?
        </FixedLabel>
        <FlexItem>
          <input
            type="checkbox"
            style={{ margin: '0.5rem 0' }}
            defaultChecked={pcr?.isCoveredTech}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('isCoveredTech', e.currentTarget.checked)}
          />
          <FixedInputGrid
            placeholder="In what way..."
            style={{ flexGrow: 1 }}
            value={pcr?.isCoveredTechExplain}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('isCoveredTechExplain', e.currentTarget.value)}
          />
        </FlexItem>
      </Row>
      <Row>
        <AlignLabel>
          Have you considered all the following categories of stakeholders to take part in the PCR committee?
        </AlignLabel>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
          <FlexItem>
            <input
              type="checkbox"
              style={{ margin: '0.5rem 0' }}
              defaultChecked={pcr?.isManufactureProducts}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangePcr('isManufactureProducts', e.currentTarget.checked)
              }
            />
            <FixedLabel style={{ flexGrow: 1, margin: '0.5rem 0' }}>
              Manufacture products in the product category.
            </FixedLabel>
          </FlexItem>
          <FlexItem>
            <input
              type="checkbox"
              style={{ margin: '0.5rem 0' }}
              defaultChecked={pcr?.isProductUser}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('isProductUser', e.currentTarget.checked)}
            />
            <FixedLabel style={{ flexGrow: 1, margin: '0.5rem 0' }}>Use product in the product category.</FixedLabel>
          </FlexItem>
          <FlexItem>
            <input
              type="checkbox"
              style={{ margin: '0.5rem 0' }}
              defaultChecked={pcr?.isProductExpert}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('isProductExpert', e.currentTarget.checked)}
            />
            <FixedLabel style={{ flexGrow: 1, margin: '0.5rem 0' }}>Are experts in the product category.</FixedLabel>
          </FlexItem>
          <FlexItem>
            <input
              type="checkbox"
              style={{ margin: '0.5rem 0' }}
              defaultChecked={pcr?.isProductRepresenter}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangePcr('isProductRepresenter', e.currentTarget.checked)
              }
            />
            <FixedLabel style={{ flexGrow: 1, margin: '0.5rem 0' }}>
              Represent manufacturers or users products in the product category.
            </FixedLabel>
          </FlexItem>
          <FlexItem>
            <input
              type="checkbox"
              style={{ margin: '0.5rem 0' }}
              defaultChecked={pcr?.haveInterest}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('haveInterest', e.currentTarget.checked)}
            />
            <FixedLabel style={{ flexGrow: 1, margin: '0.5rem 0' }}>
              Have financial interests in the product category.
            </FixedLabel>
          </FlexItem>
          <FlexItem>
            <input
              type="checkbox"
              style={{ margin: '0.5rem 0' }}
              defaultChecked={pcr?.inAccountability}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('inAccountability', e.currentTarget.checked)}
            />
            <FixedLabel style={{ flexGrow: 1, margin: '0.5rem 0' }}>Are in the chain of accountability.</FixedLabel>
          </FlexItem>
        </div>
      </Row>
      <H3 style={{ marginTop: '2rem' }}>Communication plan</H3>
      <Row>
        <FixedLabel>
          What industry associations are relevant for you to contact to make the announcement and to spread information in
          the industry?
        </FixedLabel>
        <Input
          style={{ minWidth: '60%' }}
          value={pcr?.industryAssociations}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('industryAssociations', e.currentTarget.value)}
        />
      </Row>
      <Row>
        <FixedLabel>
          How will you insure that other relevant stakeholders are aware of the PCR development and are given opportunity to
          participate?
        </FixedLabel>
        <Input
          style={{ minWidth: '60%' }}
          value={pcr?.insureStakeholders}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('insureStakeholders', e.currentTarget.value)}
        />
      </Row>
      <Row>
        <FixedLabel>
          Besides announcement on ENVIRONDEC.COM, What other communication channels will be used to inform stakeholders?
        </FixedLabel>
        <Input
          style={{ minWidth: '60%' }}
          value={pcr?.announcementChannels}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('announcementChannels', e.currentTarget.value)}
        />
      </Row>
      <H3>Expected date to start open consultation</H3>
      <Row>
        <FixedLabel>Expected start date</FixedLabel>
        <FixedInputDate
          value={formatDate(pcr?.startOpenConsultationDate)}
          type={'date'}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            e.currentTarget.value !== formatDate(pcr?.consultationDate) &&
            onChangePcr('startOpenConsultationDate', new Date(e.currentTarget.value))
          }
        />
      </Row>
    </div>
  );
};

const Tooltips = {
  explanation: 'If a PCR already exists, explain why are you proposing the development of a new PCR.',
};

const SubRow = styled.div<{ state?: ActionState }>`
  display: ${(props) => (props?.state === ActionState.Hidden ? 'none' : 'grid')};
  grid-template-rows: 2;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  justify-content: left;
  align-items: center;
  min-width: 20rem;
  ${(props) => props.theme.media.tablet} {
    min-width: auto;
    grid-template-columns: 49% 49% auto;
  }

  ${(props) =>
    props.state === ActionState.Disabled &&
    css`
      label {
        color: gray;
      }
    `}
`;

export const AlignLabel = styled(Label)`
  flex: 0 0 200px;
  align-self: start;
  margin: 1.5rem 1rem 0.5rem 0; /*risky change? */

  > svg {
    margin: 0 0.5rem;
  }

  > div + svg {
    margin: 0;
  }
`;

const FlexItem = styled.div`
  display: flex;
  gap: 2rem;
`;

const FixedInput = styled(Input)`
  min-width: 18rem;
  ${(props) => props.theme.media.tablet} {
    min-width: auto;
  }
`;

const FixedInputGrid = styled(Input)`
  min-width: 15rem;
  ${(props) => props.theme.media.tablet} {
    min-width: auto;
  }
`;

const FixedInputLca = styled(Input)`
  min-width: 15rem;
  ${(props) => props.theme.media.tablet} {
    min-width: auto;
  }
`;

const FixedInputDate = styled(Input)`
  ${(props) => props.theme.media.tablet} {
    max-width: 10rem;
  }
`;

export default WizardStep1;
