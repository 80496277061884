import React from 'react';
import { Container } from 'styles/Styles.styled';
import { useHistory, useParams } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import { WizardHeaderGrid } from 'styles/v2/Styles.styled';
import TitleAndBack from 'components/v2/TitleAndBack';
import { EpdLinks } from 'routes/EpdRoutes';
import {
  useCountriesLookup,
  useDictionaryValues,
  useEpdAdditionalInformation,
  useEpdContentDeclarationProducts,
  useEpdCoverPageInformation,
  useEpdDataSourceOptions,
  useEpdDataSources,
  useEPDDefinitionInformation,
  useEpdLcaCustomResults,
  useEpdLcaResults,
  useEpdOrganizationInformation,
  useEpdOrganizationInformationAddress,
  useEpdProductInformation,
  useEpdScenarios,
  useEpdSystemBoundaryData,
  useEpdSystemBoundaryGrid,
  useEpdVerificationReportInfo,
  useGetEpdCO2UptakeAssociation,
  useGetEpdDataQualityAssessment,
  useGetEpdLcaSupport,
  useGetEpdPCRInformation,
  useGetEpdThirdPartyVerification,
  useGetInfrastructureAndCapitalGoods,
  useGetLCASpecification,
  useLCASoftwareOptions,
  useMaterialPropertiesLookup,
  useReferencePackageVersions15804,
  useUnCpcCodes,
} from 'services/api/queries';
import EpdVersionHistoryDataContext, {
  EpdVersionHistoryData,
} from 'components/v2/compiler/version-history/utils/EpdVersionHistoryDataContext';
import VersionHistoryGeneralInformationSection from 'components/v2/compiler/version-history/content/VersionHistoryGeneralInformationSection';
import { withGreyBackground } from '../WithGreyBackground';
import VersionHistoryProductInformationSection from 'components/v2/compiler/version-history/content/VersionHistoryProductInformationSection';
import VersionHistoryContentDeclarationSection from 'components/v2/compiler/version-history/content/VersionHistoryContentDeclarationSection';
import VersionHistoryLcaInformationSection from 'components/v2/compiler/version-history/content/VersionHistoryLcaInformationSection';
import VersionHistoryReferencesSection from 'components/v2/compiler/version-history/content/VersionHistoryReferencesSection';
import VersionHistorySystemBoundarySection from 'components/v2/compiler/version-history/content/VersionHistorySystemBoundarySection';
import VersionHistoryScenariosSection from 'components/v2/compiler/version-history/content/VersionHistoryScenariosSection';
import VersionHistoryLcaResultsSection from 'components/v2/compiler/version-history/content/VersionHistoryLcaResultsSection';

interface RouteParams {
  epdId: string;
  versionId: string;
}

const EpdCompilerVersionHistory: React.FC<{}> = () => {
  const { epdId, versionId } = useParams<RouteParams>();
  const epdDefinitionInformationQuery = useEPDDefinitionInformation(versionId);
  const organizationInformationQuery = useEpdOrganizationInformation(versionId);
  const organizationInformationAddressQuery = useEpdOrganizationInformationAddress(versionId);
  const pcrInformationQuery = useGetEpdPCRInformation(versionId);
  const lcaSupportInformationQuery = useGetEpdLcaSupport(versionId);
  const thirdPartyVerificationQuery = useGetEpdThirdPartyVerification(versionId);
  const verificationReportInfoQuery = useEpdVerificationReportInfo(versionId);
  const additionalInformationQuery = useEpdAdditionalInformation(versionId);
  const coverPageInformationQuery = useEpdCoverPageInformation(versionId);
  const productsInformationQuery = useEpdProductInformation(versionId);
  const contentDeclarationProductsQuery = useEpdContentDeclarationProducts(versionId);
  const infrastructureAndCapitalGoodsQuery = useGetInfrastructureAndCapitalGoods(versionId);
  const dataQualityAssessmentAndReferenceYearsQuery = useGetEpdDataQualityAssessment(versionId);
  const lcaSpecificationQuery = useGetLCASpecification(versionId);
  const cO2UptakeAssociationQuery = useGetEpdCO2UptakeAssociation(versionId);
  const systemBoundaryQuery = useEpdSystemBoundaryData(versionId);
  const systemBoundaryGridQuery = useEpdSystemBoundaryGrid(versionId);
  const scenariosQuery = useEpdScenarios(versionId);
  const lcaResultsQuery = useEpdLcaResults(versionId);
  const lcaCustomResultsQuery = useEpdLcaCustomResults(versionId);
  const dataSourcesQuery = useEpdDataSources(versionId);
  const countriesQuery = useCountriesLookup();
  const dictionariesQuery = useDictionaryValues();
  const cpcCodesQuery = useUnCpcCodes({});
  const materialPropertiesLookupQuery = useMaterialPropertiesLookup();
  const dataSourceOptionsQuery = useEpdDataSourceOptions();
  const lcaSoftwareOptionsQuery = useLCASoftwareOptions();
  const referencePackageVersions15804Query = useReferencePackageVersions15804();

  const history = useHistory();

  const isShowingLoadingSkeleton =
    !epdDefinitionInformationQuery.data ||
    !organizationInformationQuery.data ||
    !organizationInformationAddressQuery.data ||
    !pcrInformationQuery.data ||
    !lcaSupportInformationQuery.data ||
    !thirdPartyVerificationQuery.data ||
    !verificationReportInfoQuery.data ||
    additionalInformationQuery.isPending ||
    !coverPageInformationQuery.data ||
    !productsInformationQuery.data ||
    !contentDeclarationProductsQuery.data ||
    infrastructureAndCapitalGoodsQuery.isPending ||
    !dataQualityAssessmentAndReferenceYearsQuery.data ||
    lcaSpecificationQuery.isPending ||
    !cO2UptakeAssociationQuery.data ||
    systemBoundaryQuery.isPending ||
    !systemBoundaryGridQuery.data ||
    !scenariosQuery.data ||
    !lcaResultsQuery.data ||
    !lcaCustomResultsQuery.data ||
    !dataSourcesQuery.data ||
    !countriesQuery.data ||
    !dictionariesQuery.data ||
    !cpcCodesQuery.data ||
    !materialPropertiesLookupQuery.data ||
    !dataSourceOptionsQuery.data ||
    !lcaSoftwareOptionsQuery.data ||
    !referencePackageVersions15804Query.data;

  const dataForContext: EpdVersionHistoryData | null = !isShowingLoadingSkeleton
    ? {
        epdVersionId: versionId,
        epdDefinitionInformation: epdDefinitionInformationQuery.data,
        organizationInformation: organizationInformationQuery.data,
        organizationInformationAddress: organizationInformationAddressQuery.data,
        pcrInformation: pcrInformationQuery.data,
        lcaSupportInformation: lcaSupportInformationQuery.data,
        thirdPartyVerification: thirdPartyVerificationQuery.data,
        verificationReportInfo: verificationReportInfoQuery.data,
        additionalInformation: additionalInformationQuery.data,
        coverPageInformation: coverPageInformationQuery.data,
        productsInformation: productsInformationQuery.data,
        contentDeclarationProducts: contentDeclarationProductsQuery.data,
        infrastructureAndCapitalGoods: infrastructureAndCapitalGoodsQuery.data,
        dataQualityAssessmentAndReferenceYears: dataQualityAssessmentAndReferenceYearsQuery.data,
        lcaSpecification: lcaSpecificationQuery.data,
        cO2UptakeAssociation: cO2UptakeAssociationQuery.data,
        systemBoundary: systemBoundaryQuery.data,
        systemBoundaryGrid: systemBoundaryGridQuery.data,
        scenarios: scenariosQuery.data,
        lcaResults: lcaResultsQuery.data,
        lcaCustomResults: lcaCustomResultsQuery.data,
        dataSources: dataSourcesQuery.data,
        countries: countriesQuery.data,
        dictionaries: dictionariesQuery.data,
        cpcCodes: cpcCodesQuery.data,
        materialPropertiesLookup: materialPropertiesLookupQuery.data,
        dataSourceOptions: dataSourceOptionsQuery.data,
        lcaSoftwareOptions: lcaSoftwareOptionsQuery.data,
        referencePackageVersions15804: referencePackageVersions15804Query.data,
      }
    : null;

  return (
    <EpdVersionHistoryDataContext.Provider value={dataForContext}>
      <Container>
        <WizardHeaderGrid>
          <TitleAndBack
            title={
              isShowingLoadingSkeleton ? (
                <Skeleton width="311px" height="34px" />
              ) : (
                epdDefinitionInformationQuery.data.fullIdentificationNumber
              )
            }
            backTo="Back to the EPD"
            onBack={() => history.push(EpdLinks.epd(epdId))}
          />
        </WizardHeaderGrid>

        <VersionHistoryGeneralInformationSection />

        <VersionHistoryReferencesSection />

        <VersionHistoryProductInformationSection />

        <VersionHistoryContentDeclarationSection />

        <VersionHistoryLcaInformationSection />

        <VersionHistorySystemBoundarySection />

        <VersionHistoryScenariosSection />

        <VersionHistoryLcaResultsSection />
      </Container>
    </EpdVersionHistoryDataContext.Provider>
  );
};

export default withGreyBackground(EpdCompilerVersionHistory);
