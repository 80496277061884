import { epdSelectStyles } from 'components/form/EpdSelect';
import HelpIcon from 'components/icons/HelpIcon';
import AddNewMemberRow from 'components/member/AddNewMemberRow';
import EpdDeveloperSelect from 'components/wizard/EpdSelector';
import { EpdVerifierSelect } from 'components/wizard/EpdVerifierSelector';
import PcrSelector from 'components/wizard/PcrSelector';
import { Role } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Button } from 'primereact/button';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Select, { MultiValue, SingleValue } from 'react-select';
import {
  ActionState,
  EPDModel,
  EPDVerifierModel,
  MembershipModel,
  PCRModel,
  PreverifiedToolModel,
  PreverifiedToolVersionModel,
  VerificationType,
} from 'services/EpdClient';
import styled, { css } from 'styled-components';
import { ButtonSmall, Input } from 'styles/Styles.styled';
import { Option } from 'types/types';

import { Validation } from './Validation';
import { FixedLabel, FixedRow } from './styles';
import { WizardStepValidationSchema } from './types';
import { CheckboxLabel } from 'styles/v2/Styles.styled';

type UserOption = Option & { email: string };

const WizardStep1: React.FunctionComponent<{
  epd?: EPDModel;
  epdDevelopers: MembershipModel[];
  preVerifiedTools: PreverifiedToolModel[];
  preVerifiedToolVersions: PreverifiedToolVersionModel[];
  verifiers: EPDVerifierModel[];
  onChangePcr?: any;
  onRemovePcr?: any;
  onChangeEpd?: any;
  setUpdatedEpd?: (epd: EPDModel) => void;
  refetchVerifiers?: () => void;
  validation: WizardStepValidationSchema;
}> = ({
  epd,
  epdDevelopers,
  preVerifiedTools,
  preVerifiedToolVersions,
  verifiers,
  onChangePcr,
  onRemovePcr,
  onChangeEpd,
  setUpdatedEpd,
  refetchVerifiers,
  validation,
}) => {
  const { companyId } = useContext(CompanyContext);
  const { t } = useTranslation();
  const [openEpd, setOpenEpd] = React.useState<boolean>(false);
  const [openVerifier, setOpenVerifier] = React.useState<boolean>(false);
  const selectedVerifierUser =
    epd?.epdVerifier && ({ label: epd.epdVerifier.displayName, value: epd.epdVerifier.id } as Option);
  let isValidVerifier = true;
  try {
    validation.validateSyncAt('epdVerifier', epd);
  } catch (e) {
    const validationError = e as Yup.ValidationError;
    isValidVerifier = validationError.type !== 'excluded-from-company';
  }
  const selectedContactPersonUser =
    epd?.contactPerson &&
    ({
      label: epd.contactPerson.contactName,
      value: epd.contactPerson.contactUserId,
      email: epd.contactPerson.contactEmail,
    } as UserOption);

  const changeEpdVerifier = (verifier: SingleValue<Option>) => {
    if (verifier?.value) {
      onChangeEpd('EPDVerifier', verifier.value);
    } else {
      onChangeEpd('EPDVerifier', undefined);
    }
  };

  const changeEpdContactPerson = (contactPerson: SingleValue<UserOption>) => {
    if (contactPerson?.value) {
      onChangeEpd('EPDContactPerson', contactPerson.value);
    } else {
      onChangeEpd('EPDContactPerson', undefined);
    }
  };

  const changePreverifiedTool = (tool: SingleValue<Option>) => {
    if (tool?.value) {
      onChangeEpd('preverifiedTool', tool.value);
    } else {
      onChangeEpd('preverifiedTool', undefined);
    }
  };

  const changePreverifiedToolVersion = (toolVersion: SingleValue<Option>) => {
    if (toolVersion?.value) {
      onChangeEpd('preverifiedToolVersion', toolVersion.value);
    } else {
      onChangeEpd('preverifiedToolVersion', undefined);
    }
  };

  const changePcr = (pcr: PCRModel | undefined) => {
    onChangePcr?.(pcr);
  };

  const removePcr = () => {
    onRemovePcr?.();
  };

  const changeEpdDevelopers = (developers: MultiValue<Option>) => {
    if (Array.isArray(developers)) {
      const selectedEpdDevelopers = epdDevelopers
        .filter((x) => developers.map((x) => x.value).includes(x.userId))
        .map((x) => x.id);
      onChangeEpd('EPDDevelopers', selectedEpdDevelopers);
    } else {
      onChangeEpd('EPDDevelopers', undefined);
    }
  };

  const changeVerificationType = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeEpd('VerificationType', event.currentTarget.value);
  };

  if (!epd) {
    return null;
  }

  const customVerifierSelectorStyles = {
    menu: (styles: any) => ({ ...styles }),
    control: (styles: any) => ({
      ...styles,
      borderRadius: 0,
      borderColor: isValidVerifier ? 'gray' : 'red',
      color: 'black',
      fontSize: '14px',
      minHeight: '49px',
      margin: '0.5rem 0rem',
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      fontSize: '100%',
    }),
  };

  const selectedUsers =
    epd?.epdDevelopers &&
    epd?.epdDevelopers.map((x) => {
      return { label: x.contactName, value: x.contactUserId } as Option;
    });

  const selectedPreverifiedTool =
    epd?.preverifiedTool && ({ label: epd.preverifiedTool.name, value: epd.preverifiedTool.id } as Option);

  const selectedPreverifiedToolVersion =
    epd?.preverifiedToolVersion &&
    ({ label: epd.preverifiedToolVersion.name, value: epd.preverifiedToolVersion.id } as Option);

  const handleCopyContactPersonEmailToClipboard = () => {
    if (selectedContactPersonUser && selectedContactPersonUser.value) {
      navigator.clipboard
        .writeText(selectedContactPersonUser.email)
        .then(() => {} /* ask UI if some notification needed */)
        .catch((error) => {
          console.error('Failed to copy email to clipboard:', error);
        });
    }
  };

  return (
    <div>
      <Row state={epd.registrationDateState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep1.properties.registrationNumber')}{' '}
          <HelpIcon content={t('epdWizard.wizardStep1.tooltips.registrationNumber')} />
        </FixedLabel>
        <em>{epd.registrationNumber}</em>
      </Row>
      <Row state={epd.dataSetIdState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep1.properties.uuidDataSet')}{' '}
          <HelpIcon content={t('epdWizard.wizardStep1.tooltips.uuidDataSet')} />
        </FixedLabel>
        <em>{epd.dataSetId}</em>
      </Row>
      <Row state={epd.titleState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep1.properties.title')} <HelpIcon content={t('epdWizard.wizardStep1.tooltips.title')} />{' '}
          <Validation validation={validation} field="title" epd={epd} />
        </FixedLabel>
        <Input
          defaultValue={epd.title}
          disabled={epd.titleState.actionState === ActionState.Disabled}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            e.currentTarget.value !== epd.title && onChangeEpd('title', e.currentTarget.value)
          }
        />
        <div></div>
      </Row>

      <Row state={epd.titleState.actionState}>
        <FixedLabel style={{ margin: '0.5rem 1rem 1.5rem 0' }}>
          {t('epdWizard.wizardStep1.properties.contact.title')}{' '}
          <HelpIcon content={t('epdWizard.wizardStep1.properties.contact.tooltip')} />{' '}
          <Validation validation={validation} field="contactPerson" epd={epd} />
        </FixedLabel>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Select
            placeholder={t('placeholder.defaultSelect')}
            isDisabled={epd.titleState.actionState === ActionState.Disabled}
            isMulti={false}
            styles={epdSelectStyles}
            value={selectedContactPersonUser}
            onChange={(e) => changeEpdContactPerson(e)}
            options={epdDevelopers.map((user) => {
              return {
                value: user.userId,
                label: `${user.userName} (${user.roleName})`,
                email: user.userEmail,
              } as UserOption;
            })}
          />
          <CheckboxWrapper state={epd?.contactPersonState?.actionState}>
            <StyledCheckbox
              name="sentEmailsToContactPersonFlag"
              type="checkbox"
              style={{ margin: '0' }}
              disabled={epd?.contactPersonState?.actionState === ActionState.Disabled}
              defaultChecked={epd?.sentEmailsToContactPersonFlag ?? false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeEpd('sentEmailsToContactPersonFlag', e.currentTarget.checked)
              }
            />
            <CheckboxLabel htmlFor="sentEmailsToContactPersonFlag" style={{ marginLeft: '8px' }}>
              Send EPD e-mail notifications to contact person
            </CheckboxLabel>
          </CheckboxWrapper>
        </div>
        <Button
          title="Copy Email"
          text
          className="p-2"
          style={{ margin: '0.5rem 0rem 1.5rem 0rem' }}
          onClick={handleCopyContactPersonEmailToClipboard}
        >
          <i className="pi pi-copy" style={{ fontSize: '1.5rem' }} />
        </Button>
      </Row>

      <Row state={epd.epdDevelopersState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep1.properties.epdDeveloper.lcaPractitioner')}{' '}
          <HelpIcon content={t('epdWizard.wizardStep1.tooltips.lcaPractitioner')} />{' '}
          <Validation validation={validation} field="epdDevelopers" epd={epd} />
        </FixedLabel>
        <Select
          placeholder={t('placeholder.defaultSelect')}
          isDisabled={epd.epdDevelopersState.actionState === ActionState.Disabled}
          isMulti={true}
          styles={epdSelectStyles}
          value={selectedUsers}
          onChange={(e) => changeEpdDevelopers(e)}
          options={epdDevelopers.map((user) => {
            return {
              value: user.userId,
              label: `${user.userName} (${user.roleName})`,
            } as Option;
          })}
        />

        {epd.epdDevelopersState.actionState === ActionState.Enabled && (
          <ButtonSmall
            style={{ maxWidth: '8rem' }}
            onClick={() => setOpenEpd(!openEpd)}
            title={openEpd ? '' : t('epdWizard.wizardStep1.properties.epdDeveloper.epdDeveloperMenu')}
          >
            {openEpd
              ? t('epdWizard.wizardStep1.epdDeveloperMenu.close')
              : t('epdWizard.wizardStep1.properties.epdDeveloper.addInvite')}
          </ButtonSmall>
        )}
      </Row>
      {openEpd && (
        <AccordionContent>
          <SelectDeveloperContainer>
            <p>{t('epdWizard.wizardStep1.epdDeveloperMenu.chooseEpdDeveloper')}</p>
            <div>
              <EpdDeveloperSelect epd={epd} onSelected={(updatedEpd) => setUpdatedEpd?.(updatedEpd)} />
            </div>
          </SelectDeveloperContainer>
          <InviteContainer>
            <p>{t('epdWizard.wizardStep1.epdDeveloperMenu.inviteEpdDeveloper')}</p>
            <AddNewMemberRow
              buttonText={t('epdWizard.wizardStep1.epdDeveloperMenu.inviteDeveloper')}
              companyId={companyId || ''}
              roleId={Role.EPDDeveloper}
              hideGuestOptions
            />
          </InviteContainer>
        </AccordionContent>
      )}

      <Row state={epd?.verificationTypeState?.actionState}>
        <FixedLabel style={{ alignSelf: 'start', marginTop: 0 }}>
          {t('epdWizard.wizardStep1.properties.verificationType.title')}
        </FixedLabel>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>
            <input
              disabled={epd?.verificationTypeState?.actionState === ActionState.Disabled}
              type="radio"
              id="epdprocesscertification"
              name="verification"
              value={VerificationType.EPDProcessCertification}
              checked={epd?.verificationType === VerificationType.EPDProcessCertification}
              onChange={(e) => {
                epd?.verificationTypeState?.actionState === ActionState.Enabled && changeVerificationType(e);
              }}
            />
            {t('epdWizard.wizardStep1.properties.verificationType.children.epdProcessCertification')}
          </label>
          <br />
          <label>
            <input
              disabled={epd?.verificationTypeState?.actionState === ActionState.Disabled}
              type="radio"
              id="epdverification"
              name="verification"
              value={VerificationType.EPDVerification}
              checked={epd?.verificationType === VerificationType.EPDVerification}
              onChange={(e) => {
                epd?.verificationTypeState?.actionState === ActionState.Enabled && changeVerificationType(e);
              }}
            />
            {t('epdWizard.wizardStep1.properties.verificationType.children.epdVerification')}
          </label>
        </div>
      </Row>

      <Row state={epd.preverifiedToolState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep1.properties.preVerifiedTool.title')}
          <HelpIcon content={t('epdWizard.wizardStep1.tooltips.preVerifiedTool')} />{' '}
          <Validation validation={validation} field="preverifiedTool" epd={epd} />
        </FixedLabel>
        <Select
          placeholder={t('placeholder.defaultSelect')}
          isDisabled={epd?.preverifiedToolState?.actionState === ActionState.Disabled}
          onChange={(e) => changePreverifiedTool(e)}
          value={selectedPreverifiedTool}
          isMulti={false}
          styles={epdSelectStyles}
          isClearable={true}
          options={preVerifiedTools?.map((pre) => {
            return {
              value: pre.id,
              label: pre.name,
              isDisabled:
                (pre.name === 'EPD generator for Gunnar Prefab AB' && epd.epdOwner?.companyName !== 'Gunnar Prefab AB') ||
                (pre.id === 'ee378866-dc59-4c62-5008-08dac88134de' && epd.epdOwner?.companyName !== 'DOVISTA A/S'),
            } as Option;
          })}
        />
      </Row>
      <Row state={epd.preverifiedToolState.actionState}>
        <FixedLabel>
          {'Tool Version'}
          {/* <Validation validation={validation} field="preverifiedToolVersion" epd={epd} /> */}
        </FixedLabel>
        <Select
          placeholder={t('placeholder.defaultSelect')}
          isDisabled={epd?.preverifiedToolState?.actionState === ActionState.Disabled || !epd?.preverifiedTool}
          onChange={(e) => changePreverifiedToolVersion(e)}
          value={selectedPreverifiedToolVersion}
          isMulti={false}
          styles={epdSelectStyles}
          isClearable={true}
          options={preVerifiedToolVersions
            ?.filter((x) => x.preverifiedToolId === selectedPreverifiedTool?.value)
            .map((v: any) => {
              return {
                value: v.id,
                label: v.name,
              } as Option;
            })}
        />
      </Row>
      <Row state={epd?.epdVerifierState?.actionState}>
        <FixedVerifierLabelWithParam isValidVerifier={isValidVerifier}>
          {t('epdWizard.wizardStep1.properties.epdVerifier.title')}
          <HelpIcon content={t('epdWizard.wizardStep1.tooltips.epdVerifier')} />{' '}
          <ValidationIconWithContainer>
            <Validation validation={validation} field="epdVerifier" epd={epd} />
          </ValidationIconWithContainer>
        </FixedVerifierLabelWithParam>
        <div>
          <Select
            placeholder={t('placeholder.defaultSelect')}
            isDisabled={epd?.epdVerifierState?.actionState === ActionState.Disabled}
            onChange={(e) => changeEpdVerifier(e)}
            value={selectedVerifierUser}
            isMulti={false}
            styles={customVerifierSelectorStyles}
            isClearable
            options={verifiers.map((verifier) => {
              return {
                value: verifier.id,
                label: verifier.displayName,
              } as Option;
            })}
          />
          {!isValidVerifier && (
            <div style={{ color: 'red' }}>{t('epdWizard.wizardStep1.properties.epdVerifier.notAvailable')}</div>
          )}
        </div>
        {epd?.epdVerifierState?.actionState === ActionState.Enabled && (
          <ButtonSmallWithParam
            isValidVerifier={isValidVerifier}
            style={{ maxWidth: '8rem', marginBottom: !isValidVerifier ? '3.6rem' : '0' }}
            onClick={() => setOpenVerifier(!openVerifier)}
            title={openVerifier ? '' : t('epdWizard.wizardStep1.properties.epdVerifier.epdVerifierMenu')}
          >
            {openVerifier
              ? t('epdWizard.wizardStep1.epdVerifierMenu.close')
              : t('epdWizard.wizardStep1.properties.epdVerifier.findMore')}
          </ButtonSmallWithParam>
        )}
      </Row>
      {openVerifier && (
        <AccordionContent style={{ padding: '0 0 0 1rem' }}>
          <div style={{ flex: '1 1 100%' }}>
            <p>{t('epdWizard.wizardStep1.epdVerifierMenu.needVerifier')}</p>
            {epd && <EpdVerifierSelect epd={epd} setUpdatedEpd={setUpdatedEpd} refetchVerifiers={refetchVerifiers} />}
          </div>
        </AccordionContent>
      )}

      <Row state={epd?.pcrState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep1.properties.pcr')} <HelpIcon content={t('epdWizard.wizardStep1.tooltips.pcr')} />{' '}
          <Validation validation={validation} field="pcr" epd={epd} />
        </FixedLabel>
        <PcrSelector epd={epd} onChangePcr={changePcr} onRemovePcr={removePcr} />
      </Row>

      <FixedRow state={epd?.isSectorState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep1.properties.sectorEpd')}{' '}
          <HelpIcon content={t('epdWizard.wizardStep1.tooltips.sectorEpd')} />
        </FixedLabel>
        <input
          type="checkbox"
          style={{ margin: '0.5rem 0 1rem 0' }}
          defaultChecked={epd?.isSectorEPD ?? false}
          disabled={epd?.isSectorState.actionState === ActionState.Disabled}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeEpd('isSectorEpd', e.currentTarget.checked)}
        />
      </FixedRow>
    </div>
  );
};

const SelectDeveloperContainer = styled.div`
  margin-left: 1rem;
`;

const AccordionContent = styled.div`
  border-left: solid 3px ${(props) => props.theme.colors.regionColorLight};
`;

const InviteContainer = styled(SelectDeveloperContainer)`
  > form {
    flex-direction: column;
  }

  button {
    margin-top: 0.5rem;
  }
`;

const FixedVerifierLabelWithParam = styled(FixedLabel)<{ isValidVerifier: boolean }>`
  ${(props) => props.theme.media.desktop} {
    margin: ${(props) => (!props.isValidVerifier ? '0 0 3rem 0' : '0')};
  }
`;

const ButtonSmallWithParam = styled(ButtonSmall)<{ isValidVerifier: boolean }>`
  margin: ${(props) => (!props.isValidVerifier ? '0 0 1rem 0' : '0')};
`;

const ValidationIconWithContainer = styled.div`
  display: inline;
  margin-left: 0.5rem;
  vertical-align: top;
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 16px;
  height: 16px;
  cursor: pointer;

  &:checked {
    accent-color: ${(props) => props.theme.colors.primaryOrange}; /* Works in most modern browsers */
  }
`;

const Row = styled.div<{ state?: ActionState }>`
  display: ${(props) => (props?.state === ActionState.Hidden ? 'none' : 'grid')};
  margin-bottom: 3rem;
  grid-template-rows: 3;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  justify-content: left;
  align-items: center;
  grid-template-columns: 100%;

  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 22% 50% auto;
  }

  ${(props) =>
    props.state === ActionState.Disabled &&
    css`
      label {
        color: gray;
      }
    `}
`;

const CheckboxWrapper = styled.div<{ state?: ActionState }>`
  display: ${(props) => (props?.state === ActionState.Hidden ? 'none' : 'flex')};
  align-items: center;

  ${(props) =>
    props.state === ActionState.Disabled &&
    css`
      label {
        color: gray;
      }
    `}
`;

export default WizardStep1;
