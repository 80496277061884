import { Column, ColumnBodyOptions, ColumnEvent, ColumnProps } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import React from 'react';
import {
  useCreateEpdLcaResultAdditional,
  useDeleteEpdLcaResultsAdditional,
  useUpdateEpdLcaResultAdditional,
} from 'services/api/mutationsLcaResults';
import { useEpdLcaResultsAdditional } from 'services/api/queries';
import { LcaResultModel } from 'types/types';

import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';
import DynamicGridHeader from '../DynamicGridHeader';
import { ColumnFooterInputText, DataTableStyled, Placeholder } from '../StyledGrid';
import useGridRowSelectionViaCheckbox from '../useGridRowSelectionViaCheckbox';

type TProps = {
  epdVersionId: string;
  columns?: ColumnProps[] | undefined;
  notes?: { note: React.ReactNode | string; description: React.ReactNode | string }[] | undefined;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};
const LcaResultsGridExtended = ({
  epdVersionId,
  columns,
  notes,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
}: TProps) => {
  const isReadOnly = useIsReadOnlyMode() || !!disabled;

  const rows = useEpdLcaResultsAdditional(epdVersionId).data;
  const createMutation = useCreateEpdLcaResultAdditional();
  const updateMutation = useUpdateEpdLcaResultAdditional(epdVersionId);
  const deleteMutation = useDeleteEpdLcaResultsAdditional();

  const { dataTableProps, checkboxColumnNode, handleDeleteSelected, isDeleteDisabled } =
    useGridRowSelectionViaCheckbox<LcaResultModel>(isReadOnly, deleteMutation);

  const rowClassName = () => ({ 'p-disabled': isReadOnly && !preferEnabledAppearanceEvenWhenDisabled });

  const handleAddRow = () => {
    if (!rows?.length) {
      createMutation.mutate({ epdVersionId: epdVersionId });
    }
    createMutation.mutate({ epdVersionId: epdVersionId });
  };

  const header =
    isReadOnly && preferEnabledAppearanceEvenWhenDisabled ? null : (
      <DynamicGridHeader
        caption="Indicators data"
        onAddRow={handleAddRow}
        onDeleteRows={handleDeleteSelected}
        deleteRowsDisabled={isDeleteDisabled}
      />
    );

  const footerGroup = notes ? (
    <ColumnGroup>
      {notes.map((x, i) => (
        <Row key={i}>
          <Column footer={x.note} colSpan={2} className="p-row-note" />
          <Column
            style={{ padding: '2px' }}
            footer={
              <ColumnFooterInputText
                type="text"
                placeholder="Acronym¹ description; Acronym² - description; etc."
                defaultValue={x.description as string}
                onBlur={(e) => {
                  // TODO save the value
                }}
                disabled={isReadOnly}
              />
            }
            colSpan={columns?.length}
            className={isReadOnly ? 'p-row-note' : undefined}
          />
        </Row>
      ))}
    </ColumnGroup>
  ) : null;

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field } = e;
    rowData[field] = newValue;
    updateMutation.mutate(rowData);
  };

  const placeholderCellTemplate = (data: LcaResultModel, options: ColumnBodyOptions) => {
    const { field } = options;
    let value;
    switch (field) {
      case 'impactCategory':
        value = data.impactCategory;
        return value ? (
          value
        ) : (
          <Placeholder>
            Additional voluntary impact category e.g., the global impact categories according to ISO 21930:2017
          </Placeholder>
        );
      case 'indicatorName':
        value = data.indicatorName;
        return value ? value : <Placeholder>Indicator</Placeholder>;
      case 'unit':
        value = data.unit;
        return value ? value : <Placeholder>Unit</Placeholder>;
      default:
        value = data[field as keyof LcaResultModel];
        return value;
    }
  };

  const gridData = rows && rows.length > 0 ? rows : [{ ord: 1, id: null, epdVersionId: epdVersionId }];

  return (
    <DataTableStyled
      value={gridData}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      footerColumnGroup={footerGroup}
      header={header}
      editMode="cell"
      rowClassName={rowClassName}
      {...dataTableProps}
    >
      {!(isReadOnly && preferEnabledAppearanceEvenWhenDisabled) && checkboxColumnNode}

      {columns?.map((x) => (
        <Column
          key={x.field}
          {...x}
          onCellEditComplete={x.editor ? onCellEditComplete : undefined}
          {...(isReadOnly && { editor: undefined })}
          body={placeholderCellTemplate}
        />
      ))}
    </DataTableStyled>
  );
};

export default LcaResultsGridExtended;
