import { LcaResultModel } from 'types/types';
import LcaResultsGrid from './LcaResultsGrid';
import LcaResultsGridPdf from './LcaResultsGridPdf';
import { LcaResultsGridColumnsNoCategory, LcaResultsGridColumnsNoCategoryPdf } from './LcaResultsGridColumns';

type TProps = {
  rows: LcaResultModel[] | undefined;
  isForPdf?: boolean;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};

const LcaResultsGridWasteConstruction = ({ rows, isForPdf, disabled, preferEnabledAppearanceEvenWhenDisabled }: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description: 'HWD = Hazardous waste disposed; NHWD = Non-hazardous waste disposed; RWD = Radioactive waste disposed.',
    },
  ];

  if (isForPdf) {
    return <LcaResultsGridPdf value={rows} columns={LcaResultsGridColumnsNoCategoryPdf} notes={notes} />;
  }
  return (
    <LcaResultsGrid
      value={rows}
      columns={LcaResultsGridColumnsNoCategory}
      notes={notes}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaResultsGridWasteConstruction;
