import WizardTextAreaComponent from 'components/v2/epd-wizard-inputs/WizardTextArea';
import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import { ImagesUpload } from 'components/v2/images-upload';
import SmartLabel from 'components/v2/labels/SmartLabel';
import EPDService from 'services/EpdService';
import { useAddProductInformationFileMutation, useRemoveProductInformationFileMutation } from 'services/api/mutations';
import { addEpdFile } from 'services/api/mutations';
import {
  ExpandablePanelHeaderName,
  ExpandablePanelModule,
  FieldPanelFullWidth,
  HeaderCaptionSemiBold,
} from 'styles/v2/Styles.styled';
import { EPDProductInformationModel } from 'types/types';

import ToggleButton from '../../panels/toggleable/ToggleButton';
import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import { ActionState, EPDModel } from 'services/EpdClient';
import { YES_NO } from '../../constants';
import { useState } from 'react';

const ProductDetailsSection: React.FunctionComponent<{
  product?: EPDProductInformationModel;
  epd?: EPDModel;
  epdId: string;
  epdVersionId: string;
  onChange: any;
  errors?: any;
}> = ({ product, epdId, epdVersionId, onChange, epd, errors }) => {
  const addProductInformationFileMutation = useAddProductInformationFileMutation(epdVersionId);
  const removeProductInformationFileMutation = useRemoveProductInformationFileMutation(epdVersionId);
  const productDetailsErrors = errors?.productDeclaration;
  const isTheProjectInThePlanningOrDesignPhaseLookup = [
    { value: false, label: YES_NO.NO },
    { value: true, label: YES_NO.YES },
  ];
  const [isTheProjectInThePlanningOrDesignPhaseFlag, setIsTheProjectInThePlanningOrDesignPhaseFlag] = useState<
    boolean | undefined
  >(product?.isTheProjectInThePlanningOrDesignPhase);

  const state: Record<string, ActionState> = {
    isTheProjectInThePlanningOrDesignPhase: epd?.cpcr?.isCPCR22OrCPCR23Selected ? ActionState.Enabled : ActionState.Hidden,
  };

  const onAddSystemBoundaryFile = async (propertyName: string, file: File) => {
    await addEpdFile({ epdId, propertyName, file, epdProductInformationId: product?.id }, addProductInformationFileMutation);
  };

  const onRemoveSystemBoundaryFile = async (fileId: string) => {
    removeProductInformationFileMutation.mutate(fileId);
  };

  const onChangeIsTheProjectInThePlanningOrDesignPhaseFlag = (name: any, value: any) => {
    setIsTheProjectInThePlanningOrDesignPhaseFlag(value ?? undefined);
    onChange(name, value);
  };

  const headerModuleTemplate = (options: any, header: string, required?: boolean) => {
    const isFilledIn =
      !!product?.productDescription &&
      !!product?.productIdentification &&
      !!product.productName &&
      !!product.manufacturingOrServiceProvisionDescription
        ? true
        : false;

    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{header}</HeaderCaptionSemiBold>

          {required && !isFilledIn && <TooltipErrorIcon />}
          <ToggleButton options={options} withMargins />
        </ExpandablePanelHeaderName>
      </div>
    );
  };

  return (
    <ExpandablePanelModule headerTemplate={(options) => headerModuleTemplate(options, 'Product details', true)} toggleable>
      <FieldPanelFullWidth>
        <WizardTextInputComponent
          label="Product name"
          tooltip="Name of the product included in the EPD."
          onChanged={onChange}
          required
          placeholder="Unnamed"
          name="productName"
          value={product?.productName}
          error={productDetailsErrors?.name}
        />
        <CompilerSelectControlledComponent
          label="Is the project in the planning/design phase?"
          tooltip="Define is the project in the planning/design phase"
          name="isTheProjectInThePlanningOrDesignPhase"
          options={isTheProjectInThePlanningOrDesignPhaseLookup}
          value={isTheProjectInThePlanningOrDesignPhaseLookup?.find(
            (x) => x.value === isTheProjectInThePlanningOrDesignPhaseFlag
          )}
          onChanged={onChangeIsTheProjectInThePlanningOrDesignPhaseFlag}
          state={state}
          placeholder="Select..."
          error={productDetailsErrors?.isTheProjectInThePlanningOrDesignPhase}
        />
        <WizardTextAreaComponent
          label="Product identification"
          required
          placeholder="Type here"
          tooltip="Product identification by an unambiguous identification of the product by standards, concessions, or other means."
          value={product?.productIdentification}
          onChanged={onChange}
          name="productIdentification"
          error={productDetailsErrors?.productIdentification}
        />
        <WizardTextAreaComponent
          label="Product description"
          required
          placeholder="Type here"
          tooltip="Description of the product in accordance with the product classification system(s) used, and description of the technical performance of the product, including its key functionalities"
          value={product?.productDescription}
          onChanged={onChange}
          name="productDescription"
          error={productDetailsErrors?.productDescription}
        />
        <WizardTextAreaComponent
          label="Product information from external sources"
          placeholder="Type here"
          tooltip="References to any relevant websites for more information or explanatory materials."
          value={product?.productInformationExternal}
          onChanged={onChange}
          name="productInformationExternal"
        />
        <div>
          <SmartLabel
            label={'Product images shown on EPD'}
            required
            hasValue={!!product?.productDetailsImages?.length}
            tooltip="Product images shown on www.environdec.com and in the EPD."
            error={productDetailsErrors?.productInformationsToFiles}
          />
          <ImagesUpload
            name={'ProductDetailsImages'}
            images={product?.productDetailsImages}
            onUpload={onAddSystemBoundaryFile}
            onReorder={(imgIds: string[]) => EPDService.arrangeEpdFilesByModifyDate(imgIds)}
            onRemove={onRemoveSystemBoundaryFile}
          />
        </div>
        <WizardTextAreaComponent
          label="Manufacturing or service provision description"
          placeholder="Type here"
          tooltip="Brief description of main processes of manufacturing (for EPDs of goods) or service provision (for EPDs of services)."
          value={product?.manufacturingOrServiceProvisionDescription}
          onChanged={onChange}
          name="manufacturingOrServiceProvisionDescription"
          error={productDetailsErrors?.manufacturingOrServiceProvisionDescription}
        />
      </FieldPanelFullWidth>
    </ExpandablePanelModule>
  );
};

export default ProductDetailsSection;
