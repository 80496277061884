import heroImage from 'assets/images/hero-image.png';
import logo from 'assets/images/portal-logo.svg';
import { PrimaryButtonLink, SecondaryButtonLink } from 'components/v2/buttons';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EpdRoutes, { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import styled from 'styled-components';
import { A, H1, Text } from 'styles/Styles.styled';
import { EndColumn, LoginFlowA, StartColumn } from 'styles/Styles.styled';

interface Props {}

const Welcome: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { changeCompany } = useContext(CompanyContext);
  const history = useHistory();

  const redirectToDashboard = (result: any) => {
    if (AuthService.isPcrUser()) {
      history.push(EpdRoutes.pcr.dashboard);
    } else if (result?.memberships && result?.memberships.length > 0) {
      changeCompany?.(result?.memberships[0].companyId);
      history.push(EpdLinks.dashboard());
    } else {
      history.push(EpdLinks.dashboard());
    }
  };

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      redirectToDashboard(AuthService.getUser());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WelcomeContainer>
      <StartColumn>
        <HeaderLogo src={logo} alt="EPD International" />
        <TextBlock>
          <H1>Welcome to the EPD portal!</H1>
          <Paragraph>
            We help organizations register and manage EPDs online. The EPD Portal guides you step-by-step through the EPD
            registration process.
          </Paragraph>
          <Paragraph>Registration is free of charge.</Paragraph>
          <Paragraph>
            Find more information about EPDs on <LoginFlowA href="https://www.environdec.com">www.environdec.com</LoginFlowA>
            .
          </Paragraph>
          <ButtonContainer>
            <PrimaryButtonLink to={'/login'} style={{ marginRight: '2rem' }}>
              Login
            </PrimaryButtonLink>
            <SecondaryButtonLink to={'/register/user'}>Register</SecondaryButtonLink>
          </ButtonContainer>
        </TextBlock>
        <Footer>
          {t('footer.text')}
          <span style={{ marginLeft: '1rem', marginRight: '1rem' }}>{'|'}</span>
          <A href={t('footer.link')}>{t('footer.linkText')}</A>
        </Footer>
      </StartColumn>
      <EndColumn>
        <img src={heroImage} alt="hero" style={{ maxHeight: '100vh', alignSelf: 'end' }} />
      </EndColumn>
    </WelcomeContainer>
  );
};

const WelcomeContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  width: 100%;
`;

const HeaderLogo = styled.img`
  align-self: flex-start;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`;

const Paragraph = styled(Text)`
  margin-top: 1rem;
  color: #151515;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

const TextBlock = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  margin: 3rem 0;
  > a {
    min-width: 190px;
  }
`;

export default Welcome;
