import Checkbox from 'components/form/Checkbox';
import PasswordInput from 'components/form/PasswordInput';
import { ProcessStatus } from 'constants/constants';
import { Formik } from 'formik';
import queryString from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import { ResetPasswordModel } from 'services/EpdClient';
import { Button, CenterColumn, Container, ErrorText, H2, InfoBox, StyledForm, SuccessText } from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

import warningIcon from '../../images/icons/svg/warning.svg';
import { acceptLabel } from './RegisterUser';

const ResetPassword: React.FunctionComponent = () => {
  const [error, setError] = React.useState<string>('');
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const location = useLocation();
  const values = queryString.parse(location.search);
  const userName = values?.userName?.toString();
  const token = values?.token?.toString();
  const { t } = useTranslation();

  type ExtendedResetPasswordModel = ResetPasswordModel | { acceptTerms: boolean };

  const initialValues: ExtendedResetPasswordModel = {
    userName: userName || '',
    password: '',
    confirmPassword: '',
    token: token || '',
    acceptTerms: false,
  };

  const resetPasswordSchema = Yup.object({
    password: Yup.string()
      .required(t('account.validation.passwordRequired'))
      .nullable()
      .matches(/\w*[a-z]\w*/, t('account.validation.invalidPasswordSmallLetter'))
      .matches(/\w*[A-Z]\w*/, t('account.validation.invalidPasswordCapitalLetter'))
      .matches(/\d/, t('account.validation.invalidPasswordNumber'))
      .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, t('account.validation.invalidPasswordSpecial'))
      .min(6, ({ min }) => t('account.validation.passwordLength', { min })),
    confirmPassword: Yup.string()
      .nullable()
      .oneOf([Yup.ref('password')], t('account.validation.passwordsNotMatch'))
      .required(t('account.validation.confirmPasswordRequired')),
    acceptTerms: Yup.boolean()
      .required('The terms and conditions must be accepted.')
      .oneOf([true], 'The terms and conditions must be accepted.'),
  });

  const resetPasword = async (values: ResetPasswordModel) => {
    try {
      setStatus(ProcessStatus.Fetching);
      await AuthService.resetPassword(values);
      setStatus(ProcessStatus.Success);
    } catch (e) {
      const error = PoorMansError(e);
      setStatus(ProcessStatus.Error);
      setError(error ? error : 'Something went wrong. Try again later.');
    }
  };

  if (!token) {
    return (
      <Container>
        <CenterColumn>
          <H2>Set your password?</H2>
          <p>Oops. It appears the link is you used to reset your password is faulty.</p>
        </CenterColumn>
      </Container>
    );
  }

  return (
    <Container>
      <CenterColumn>
        <H2>Set your password?</H2>
        <p>
          Welcome to the EPD Portal! Please choose a new password and confirm it by filling out the form below. Upon your
          first log-in, we kindly ask you to please make sure that your contact information and the information from your
          organization in the portal are updated and saved.
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={resetPasswordSchema}
          onSubmit={(values) => resetPasword(values as ResetPasswordModel)}
        >
          <StyledForm>
            <InfoBox>
              <img src={warningIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
              <div>{t('account.setPasswordWarning')}</div>
            </InfoBox>
            <PasswordInput label={t('account.password')} name="password" />
            <PasswordInput label={t('account.confirmPassword')} name="confirmPassword" />
            <Checkbox
              label={acceptLabel}
              inputStyle={{ margin: '0.75rem 0.75rem 0.75rem 0' }}
              name="acceptTerms"
              id="acceptTerms"
            />
            <Button disabled={status === ProcessStatus.Fetching}>
              {status === ProcessStatus.Fetching ? 'Resetting password...' : 'Set password'}
            </Button>

            {status === ProcessStatus.Error && <ErrorText>{error}</ErrorText>}
            {status === ProcessStatus.Success && (
              <SuccessText>
                Your password has been reset!
                <br />
                <Link to={EpdLinks.dashboard()}>Go to the Dashboard</Link>
              </SuccessText>
            )}
          </StyledForm>
        </Formik>
      </CenterColumn>
    </Container>
  );
};

export default ResetPassword;
