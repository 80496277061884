import { Column, ColumnBodyOptions, ColumnEvent } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import React from 'react';
import {
  useCreateEpdScrapInput,
  useCreateEpdShareOfTotalScrapInput,
  useDeleteEpdScrapInputs,
  useUpdateEpdScrapInput,
  useUpdateEpdShareOfTotalScrapInput,
} from 'services/api/mutations';
import { useGetEpdScrapInputs, useGetEpdShareOfTotalScrapInput } from 'services/api/queries';
import { ScrapInputModel } from 'types/types';

import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';
import DynamicGridHeader from '../DynamicGridHeader';
import { cellTextEditor } from '../GridCellEditors';
import { cellTemplateWithPlaceholder } from '../GridService';
import { ColumnFooterInputNumber, DataTableStyled } from '../StyledGrid';
import useGridRowSelectionViaCheckbox from '../useGridRowSelectionViaCheckbox';

const ContributionOfScrapInputsGrid: React.FunctionComponent<{ epdVersionId: string; errors?: any }> = ({
  epdVersionId,
  errors,
}) => {
  const isReadOnly = useIsReadOnlyMode();

  const shareOfTotalScrapInput = useGetEpdShareOfTotalScrapInput(epdVersionId!).data;
  const createShareOfTotalScrapInput = useCreateEpdShareOfTotalScrapInput();
  const updateShareOfTotalScrapInput = useUpdateEpdShareOfTotalScrapInput();

  const rows = useGetEpdScrapInputs(epdVersionId!).data;
  const createScrapInputMutation = useCreateEpdScrapInput();
  const updateScrapInputMutation = useUpdateEpdScrapInput();
  const deleteMutation = useDeleteEpdScrapInputs();

  const { dataTableProps, checkboxColumnNode, handleDeleteSelected, isDeleteDisabled } =
    useGridRowSelectionViaCheckbox<ScrapInputModel>(isReadOnly, deleteMutation);

  const rowClassName = () => ({ 'p-disabled': isReadOnly });

  const handleAddRow = () => {
    // initially add default row in case the table is empty and has default empty row
    if (!rows?.length) {
      createScrapInputMutation.mutate({ epdVersionId: epdVersionId });
    }
    createScrapInputMutation.mutate({ epdVersionId: epdVersionId });
  };

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;
    updateScrapInputMutation.mutate(rowData);
  };

  const cellTemplate = (placeholder: string, value: ScrapInputModel, options: ColumnBodyOptions) =>
    cellTemplateWithPlaceholder((value as any)?.[options?.field], placeholder);

  const cellTemplateFormatted = (placeholder: string, value: ScrapInputModel, options: ColumnBodyOptions) =>
    cellTemplateWithPlaceholder(formatScrapInputValue((value as any)?.[options?.field]), placeholder);

  const isTableFilledIn = rows?.every((r) => r.name) && rows?.every((r) => r.value);

  const header = (
    <DynamicGridHeader
      hasValue={isTableFilledIn}
      caption="Scrap inputs data"
      onAddRow={handleAddRow}
      onDeleteRows={handleDeleteSelected}
      deleteRowsDisabled={isDeleteDisabled}
      error={
        errors?.general || (!isTableFilledIn && 'Required') || (errors && Object.keys(errors).length > 0 && 'Has errors')
      }
      required
    />
  );

  const onFooterCellEditComplete = (e: any) => {
    const value = e.target.value?.replace(' %', '');
    if (!shareOfTotalScrapInput?.id) {
      createShareOfTotalScrapInput.mutate({ epdVersionId, sharePercent: value! ?? null });
      return;
    }
    updateShareOfTotalScrapInput.mutate({ ...shareOfTotalScrapInput, sharePercent: value! ?? null });
  };

  const footerNumberEditor = () => (
    <ColumnFooterInputNumber
      placeholder="Type value % "
      value={shareOfTotalScrapInput?.sharePercent}
      suffix=" %"
      onBlur={onFooterCellEditComplete}
      mode="decimal"
      step={0.01}
      maxFractionDigits={2}
      disabled={isReadOnly}
    />
  );

  const footerGroup = (
    <ColumnGroup>
      <Row style={{ borderTop: 'none' }}>
        <Column className="p-row-note" />
        <Column
          footer={<div>The share of the total scrap input that was assumed to come with an environmental burden</div>}
          className="p-row-note"
        />
        <Column footer={footerNumberEditor} colSpan={4} className={isReadOnly ? 'p-row-note' : undefined} />
      </Row>
    </ColumnGroup>
  );

  const formatScrapInputValue = (value: number | undefined) => (!!value ? `${value}, kg CO2 eq./kW` : null);

  const gridData = rows && rows.length > 0 ? rows : [{ ord: 1, id: null, epdVersionId: epdVersionId }];

  return (
    <DataTableStyled
      dataKey="id"
      header={header}
      footerColumnGroup={footerGroup}
      value={gridData}
      editMode="cell"
      rowClassName={rowClassName}
      showGridlines
      {...dataTableProps}
    >
      {checkboxColumnNode}
      <Column
        field="name"
        header="Material scrap name"
        editor={isReadOnly ? undefined : cellTextEditor}
        onCellEditComplete={onCellEditComplete}
        body={(data, options) => cellTemplate('Type name', data, options)}
      />
      <Column
        field="value"
        header="Material scrap value"
        body={(data, options) => cellTemplateFormatted('Type value, kg CO2 eq./kg', data, options)}
        editor={isReadOnly ? undefined : cellTextEditor}
        onCellEditComplete={onCellEditComplete}
      />
    </DataTableStyled>
  );
};

export default ContributionOfScrapInputsGrid;
