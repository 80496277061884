import styled from 'styled-components';

export const PanelTitle = styled.div`
  ${(props) => props.theme.typography.headerCaptionSemiBold}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.75rem 0rem;
  margin: 0;
`;
