import ErrorFallback from 'components/error-fallback/ErrorFallback';
import { EditDataIcon } from 'components/v2/icons';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import { Column, ColumnEvent, ColumnProps } from 'primereact/column';
import { DataTableValueArray } from 'primereact/datatable';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useUpdateCpcrSpecificTableColumn, useUpdateCpcrSpecificTableRow } from 'services/api/mutations';
import styled from 'styled-components';

import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';
import { PanelTitle } from '../../panels/Styles.styled';
import { cellTextEditor } from '../GridCellEditors';
import { ColumnHeaderInput, DataTableStyled, Placeholder } from '../StyledGrid';

interface ColumnPropsExtended extends ColumnProps {
  isDataReadonly?: boolean;
  isTitleReadonly?: boolean;
  placeholder?: string;
}

type TProps = {
  epdVersionId: string;
  tableTitle?: string;
  tableCode: string;
  value?: DataTableValueArray | undefined;
  columns?: ColumnPropsExtended[] | undefined;
  notes?: { note: React.ReactNode | string; description: React.ReactNode | string }[] | undefined;
  errors: any;
  tooltip?: string | undefined;
  tooltipPosition?: any | undefined;
};

const FixedGrid = ({
  epdVersionId,
  tableTitle,
  tableCode,
  value,
  columns,
  notes,
  errors,
  tooltip,
  tooltipPosition,
}: TProps) => {
  const isReadOnly = useIsReadOnlyMode();
  const updateMutation = useUpdateCpcrSpecificTableRow(epdVersionId);
  const updateColumnHeaderMutation = useUpdateCpcrSpecificTableColumn(epdVersionId);

  const rowClassName = (rowData: any) => ({
    'p-disabled': isReadOnly,
    [rowData.rowClassName]: rowData.rowClassName,
  });

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;
    updateMutation.mutate(rowData);
  };

  const onHeaderEdit = (e: any, column: any) => {
    updateColumnHeaderMutation.mutate({ id: column.id, header: e.target.value });
  };

  const columnHeaderTemplate = (column: any, index: any) => {
    return column.isHeaderReadonly ? (
      column.header
    ) : (
      <div style={{ display: 'grid', gridTemplateColumns: 'auto  1rem', columnGap: '0.5rem', minWidth: '8rem' }}>
        <div>
          <ColumnHeaderInput type="text" defaultValue={column.header} onBlur={(e) => onHeaderEdit(e, column)} />
        </div>
        <div>
          <EditDataIcon />
        </div>
      </div>
    );
  };

  const renderCellBody = (row: any, options: any) => {
    return (
      (row as any)?.[options?.field] || (
        <Placeholder>{(row as any)?.[`${options?.field}Placeholder`] || 'Type here'}</Placeholder>
      )
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div>
        <div style={{ display: 'flex', alignSelf: 'auto', alignItems: 'Center' }}>
          <PanelTitle style={{ paddingTop: 0 }}>{tableTitle}</PanelTitle>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {/* {errors && <TooltipErrorIcon content={error || 'Required'} />} */}
        </div>
        <DataTableStyledHeader
          value={value}
          dataKey="id"
          tableStyle={{ minWidth: '49rem' }}
          showGridlines
          editMode="cell"
          rowClassName={rowClassName}
        >
          {columns?.map((x, index) => (
            <Column
              key={x.field}
              header={columnHeaderTemplate(x, index)}
              body={(row, options) => renderCellBody(row, options)}
              onCellEditComplete={x.isDataReadonly ? undefined : onCellEditComplete}
              editor={isReadOnly || x.isDataReadonly ? undefined : (options) => cellTextEditor(options)}
              {...x}
            />
          ))}
        </DataTableStyledHeader>
      </div>
    </ErrorBoundary>
  );
};

const DataTableStyledHeader = styled(DataTableStyled)`
  &.p-datatable .p-column-header-content {
    display: block;
  }
`;

export default FixedGrid;
