import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  addEpdFile,
  useAddEpdDocument,
  useRemoveEpdDocument,
  useUpdateEpdAdditionalInformation,
} from 'services/api/mutations';
import { useEpdAdditionalInformation } from 'services/api/queries';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';

import WizardHTMLEditor from '../epd-wizard-inputs/WizardHTMLEditor';
import styled from 'styled-components';
import { EPDModel, FileLoadingModel, LanguageModel, UploadDocumentTypesModel } from 'services/EpdClient';
import { Option } from 'types/types';
import UploadDocumentTypesService from 'services/UploadDocumentTypesService';
import LanguagesService from 'services/LanguagesService';
import { HelpBox } from '../help-boxes';
import CompilerSelectControlledComponent from '../epd-compiler-inputs/CompilerSelectControlledComponent';
import FileUpload from '../file-upload/FileUpload';
import FilesList from '../file-row/FilesList';
import { ButtonUploadIcon } from '../icons';
import { createFilter } from 'react-select';

type OptionWithCode = Option & { code?: string };

const AdditionalInformationTab: React.FunctionComponent<{
  epd: EPDModel;
  epdVersionId: string;
  isReadOnly: boolean;
  fileLoading?: FileLoadingModel[];
}> = ({ epd, epdVersionId, fileLoading }) => {
  const additionalInformation = useEpdAdditionalInformation(epdVersionId).data;

  const updateMutation = useUpdateEpdAdditionalInformation(epdVersionId);

  const onChangeEpdAdditionalInformation = async (propertyName: string, val: any) => {
    updateMutation.mutate({ epdId: epd?.id!, versionId: epdVersionId, propertyName: propertyName, newValue: val });
  };

  const [documentTypeList, setDocumentTypes] = useState<UploadDocumentTypesModel[]>([]);
  const [languageList, setLanguages] = useState<LanguageModel[]>([]);

  const [documentType, setDocumentType] = useState<Option>();
  const [documentLanguage, setDocumentLanguage] = useState<OptionWithCode>();

  const addEPDDocumentMutation = useAddEpdDocument(epd?.id!);
  const removeEPDDocumentMutation = useRemoveEpdDocument(epd?.id!, epdVersionId);

  const onRemoveEPDDocument = async (removeCommandAlias: string, fileId: string) => {
    removeEPDDocumentMutation.mutate(fileId);
  };

  useEffect(() => {
    const fetchDocumentType = async () => {
      const res = await UploadDocumentTypesService.getDocumentTypes();
      setDocumentTypes(res);
      return res;
    };
    const fetchLanguages = async () => {
      const result = await LanguagesService.getLanguages();
      setLanguages(result);
    };

    fetchLanguages();
    fetchDocumentType();
  }, []);

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: 'start' as const,
  };

  const renameAndUploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];

    if (!file || !epd) {
      return null;
    }

    if (documentType?.value !== '2489c33d-56f6-4fe7-b5cd-a94e82900708') {
      propertyName = 'DocumentOther';
    }

    let newName = renameDocument(file?.name.split('.').pop() || '');
    await addEpdFile(
      { epdId: epd.id!, propertyName, file: new File([file], newName, { type: file.type }) },
      addEPDDocumentMutation
    );

    // @ts-ignore
    setDocumentType(null);
    // @ts-ignore
    setDocumentLanguage(null);
  };

  // TODO make proper file upload component, this code just copied
  const renameDocument = (fileExtension: string) => {
    let dublicates = 0;
    let newName = '';
    let nameFound = false;
    while (!nameFound) {
      let numDoc = dublicates === 0 ? '' : `(${dublicates})`;
      newName = `${documentType?.label} ${
        epd?.fullIdentificationNumber
      } ${documentLanguage?.code?.toLocaleUpperCase()}${numDoc}.${fileExtension}`;
      if (
        epd?.documents &&
        epd.documents.filter((x) => x.name?.toLocaleLowerCase() === newName.toLocaleLowerCase()).length > 0
      ) {
        dublicates += 1;
      } else nameFound = true;
    }

    return newName;
  };

  return (
    <>
      <FieldPanelFullWidth style={{ gridRowGap: 0 }}>
        <HelpBox>
          <span>
            Please upload EPD related documents in PDF format. Several documents can be uploaded.
            <br />
            Document type and language should be specified before uploading.
          </span>
        </HelpBox>
        <DoubleFieldPanel style={{ marginTop: 30 }}>
          <CompilerSelectControlledComponent
            label="Document type"
            tooltip="EPD realated documents."
            name="docType"
            options={documentTypeList?.map((item) => {
              return {
                value: item.id,
                label: item.name,
              } as Option;
            })}
            value={documentType}
            onChanged={(name: string, value: string) => {
              if (!value) {
                setDocumentType(undefined);
                return;
              }
              setDocumentType({ value: value, label: documentTypeList.find((x) => x.id == value)?.name || value });
            }}
            isClearable={true}
          />
          <CompilerSelectControlledComponent
            label="Document language"
            tooltip="Specify the language of the provided document."
            name="docLang"
            options={languageList?.map((item) => {
              return {
                value: item.id,
                label: item.name,
                code: item.code,
              } as OptionWithCode;
            })}
            value={documentLanguage}
            onChanged={(name: string, value: string) => {
              setDocumentLanguage({
                value: value,
                label: languageList.find((x) => x.id == value)?.name || value,
                code: languageList.find((x) => x.id == value)?.code,
              });
            }}
            isClearable={false}
            filterOption={createFilter(filterConfig)}
          />
        </DoubleFieldPanel>
        <FileUpload
          label="Upload document"
          name="Document"
          icon={ButtonUploadIcon}
          accept=".pdf"
          validExtensions={['pdf']}
          onFileUpload={renameAndUploadFile}
          disabled={!(documentLanguage && documentType)}
        />
        <FilesList
          files={epd?.documents}
          onRemoveFile={onRemoveEPDDocument}
          fileLoading={
            fileLoading?.find(
              (loading) =>
                (loading.fileType === 'Document' || loading.fileType === 'DocumentOther') && loading.loadingsCount > 0
            ) !== undefined
          }
        />
      </FieldPanelFullWidth>
      {additionalInformation !== undefined && (
        <FieldPanelFullWidth>
          <WizardHTMLEditor
            name="additional.environmental"
            value={additionalInformation?.environmental}
            label="Additional environmental information"
            tooltip="An EPD may include additional environmental information not derived from the LCA. The additional environmental information may cover various aspects of specific relevance for the product."
            tooltipPosition="right"
            onChanged={onChangeEpdAdditionalInformation}
            stripPastedStyles={true}
          />
          <WizardHTMLEditor
            name="additional.conversion"
            value={additionalInformation?.conversion}
            label="Conversion factors"
            tooltip="Conversion factors may be included in an EPD for the purposes of converting the declared results of a product group to results for specific products within the group, or converting the declared results to results for another declared/functional unit. Conversion factors can, however, not be included for the purpose of converting the declared results into results for products not covered by the EPD."
            tooltipPosition="right"
            onChanged={onChangeEpdAdditionalInformation}
            stripPastedStyles={true}
          />
          <WizardHTMLEditor
            name="additional.dangerous"
            value={additionalInformation?.dangerous}
            label="Dangerous substance to indoor air, soil, and water during the use stage"
            tooltip="Emissions to indoor air, soil, and water according to the horizontal standards on measurement of release of regulated dangerous substances from construction products using harmonised testing methods according to the provisions of the respective Technical Committees for European product standards, when available."
            tooltipPosition="right"
            onChanged={onChangeEpdAdditionalInformation}
            stripPastedStyles={true}
          />
          <WizardHTMLEditor
            name="additional.economic"
            value={additionalInformation?.economic}
            label="Economic and social information"
            tooltip="The EPD may also include other relevant social and economic information as additional and voluntary information. This may be product information or a description of an organization's overall work on social or economic sustainability, such as activities related to supply chain management or social responsibility."
            tooltipPosition="right"
            onChanged={onChangeEpdAdditionalInformation}
            stripPastedStyles={true}
          />
        </FieldPanelFullWidth>
      )}
    </>
  );
};

const DoubleFieldPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  justify-content: start;

  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 1fr 1fr;
  }
`;

export default AdditionalInformationTab;
