import Checkbox from 'components/form/Checkbox';
import LargeRadiusTextInput from 'components/form/LargeRadiusTextInput';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Formik } from 'formik';
import warningIcon from 'images/icons/svg/warning.svg';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import { EPDModel, EPDStatus } from 'services/EpdClient';
import EPDService from 'services/EpdService';
import styled from 'styled-components';
import {
  AdminBox,
  AdminSearchGridForm,
  ButtonSmall,
  Container,
  EditFull,
  H2,
  InfoBox,
  InfoText,
  Label,
  LabelBox,
  SearchBox,
  ValueBox,
} from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

import CompanySelector from './CompanySelector';

const EditEpd: React.FunctionComponent = () => {
  const { epdid } = useParams<{ epdid: string }>();
  const [epd, setEpd] = useState<EPDModel>();
  const [initialValues, setInitialValues] = useState<any>(null);
  const [openEpd, setOpenEpd] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:44380';
  const [confirmMessage, setConfirmMessage] = useState<string>('');
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { changeCompany } = useContext(CompanyContext);

  const validationSchema = Yup.object({
    registrationNumber: Yup.number(),
    name: Yup.string(),
    isDualRegistration: Yup.boolean(),
  });

  const onSaveChanges = async (values: any) => {
    const excludedKeys = ['name', 'registrationNumber'];
    for (let key of Object.keys(values).filter((x) => !excludedKeys.includes(x))) {
      try {
        await EPDService.updateProperty(epdid, key, values[key]);
        setConfirmMessage('EPD information has been saved.');
        loadEpd();
      } catch (error) {
        setErrorMessage(PoorMansError(error));
      }
    }
  };

  const loadEpd = async () => {
    try {
      const result = await EPDService.getEpd(epdid);
      setEpd(result);
      setIsLoaded(true);
      if (result) {
        setInitialValues({
          registrationNumber: result.registrationNumber,
          name: result.name,
          company: result.epdOwner?.companyName,
          isDualRegistration: result.isDualRegistration,
        });
      }
    } catch (error) {
      setErrorMessage(PoorMansError(error));
    }
  };

  useEffect(() => {
    loadEpd();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!initialValues && !errorMessage) {
    return <InfoText>Loading...</InfoText>;
  }
  return (
    <Container>
      <EditFull>
        <H2>EPD details</H2>
        {isLoaded && (
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSaveChanges}>
            <>
              {epd?.epdOwner?.companyId && (
                <Link to={EpdLinks.epd(epdid)} onClick={() => changeCompany?.(epd?.epdOwner?.companyId)}>
                  <ButtonSmall style={{ marginBottom: '10px' }} type="button" disabled={false}>
                    View EPD object
                  </ButtonSmall>
                </Link>
              )}
              {AuthService.isAdmin() && epd?.epdOwner?.companyId && (
                <Link to={EpdLinks.ilcdEpdMappingList(epdid)}>
                  <ButtonSmall style={{ marginBottom: '10px', marginLeft: '10px' }} type="button" disabled={false}>
                    Export to ILCD + EPD
                  </ButtonSmall>
                </Link>
              )}
              <AdminBox style={{ backgroundColor: '#FDFDFD' }}>
                <AdminSearchGridForm>
                  <LabelBox>
                    <FixedLabel>Registration number</FixedLabel>
                  </LabelBox>
                  <ValueBox>
                    <LargeRadiusTextInput
                      style={{ boxSizing: 'border-box', width: '100%' }}
                      name="registrationNumber"
                      type="number"
                      disabled={true}
                    />
                  </ValueBox>

                  <LabelBox>
                    <FixedLabel>EPD Name</FixedLabel>
                  </LabelBox>
                  <ValueBox>
                    <LargeRadiusTextInput
                      style={{ boxSizing: 'border-box', width: '100%' }}
                      name="name"
                      type="text"
                      disabled={true}
                    />
                  </ValueBox>

                  <LabelBox>
                    <FixedLabel>Company</FixedLabel>
                  </LabelBox>
                  <ValueBox>
                    <LargeRadiusTextInput
                      style={{ boxSizing: 'border-box', width: '100%' }}
                      name="company"
                      type="text"
                      disabled={true}
                    />
                  </ValueBox>

                  <LabelBox>
                    <FixedLabel>Dual Registration</FixedLabel>
                  </LabelBox>
                  <ValueBox style={{ minHeight: 'auto' }}>
                    <Checkbox name="isDualRegistration" style={{ margin: 0 }} />
                  </ValueBox>

                  <SearchBox>
                    <ButtonSmall style={{ padding: '0.5rem 1rem', borderRadius: '3px' }} type="submit">
                      Save
                    </ButtonSmall>
                  </SearchBox>

                  {epd?.status === EPDStatus.Reserved && (
                    <div style={{ gridColumnStart: '2', gridColumnEnd: '-1', marginTop: '1rem' }}>
                      <ButtonSmall onClick={() => setOpenEpd(!openEpd)} type="button">
                        Change account
                      </ButtonSmall>
                      {openEpd && (
                        <AccordionContainer>
                          <AccordionContent>
                            <SelectCompanyContainer>
                              <p>Select the organization from the list below.</p>
                              <div>
                                <CompanySelector epdId={epdid} />
                              </div>
                            </SelectCompanyContainer>
                          </AccordionContent>
                        </AccordionContainer>
                      )}
                    </div>
                  )}
                </AdminSearchGridForm>
              </AdminBox>
            </>
          </Formik>
        )}
        {errorMessage && (
          <InfoBox style={{ borderLeft: 'solid 3px red', display: 'flex', alignItems: 'center' }}>
            <img src={warningIcon} alt="error" style={{ height: '35px', marginRight: '1rem' }} />
            <div style={{ color: 'red' }}>{PoorMansError(errorMessage)}</div>
          </InfoBox>
        )}
        {confirmMessage && (
          <div style={{ margin: '1rem 0', fontSize: '16px' }}>
            <div style={{ color: 'green' }}>{confirmMessage}</div>
          </div>
        )}
      </EditFull>
    </Container>
  );
};

const AccordionContainer = styled.div`
  margin: 2rem 0;
  border: solid 1px black;
  background-color: ${(props) => props.theme.colors.lightGray};
  width: 100%;
`;

const AccordionContent = styled.div`
  border-left: solid 3px ${(props) => props.theme.colors.regionColorLight};
  padding: 0;
  display: flex;
  width: 100%;
`;

const SelectCompanyContainer = styled.div`
  margin-left: 1rem;
`;
const FixedLabel = styled(Label)`
  flex: 0 0 200px;
  align-self: center;
  margin: 0.5rem 1rem 0.5rem 0; /*risky change? */

  > svg {
    margin: 0 0.5rem;
  }

  > div + svg {
    margin: 0;
  }
`;

export default EditEpd;
