import Checkbox from 'components/form/Checkbox';
import PasswordInput from 'components/form/PasswordInput';
import TextInput from 'components/form/TextInput';
import { Formik } from 'formik';
import infoIcon from 'images/icons/svg/warning.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AuthService from 'services/AuthService';
import { RegisterModel } from 'services/EpdClient';
import { A, Button, CenterColumn, Container, ErrorText, H2, InfoBox, StyledForm, SuccessText } from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

const initialValues: RegisterModel | { acceptTerms: boolean } = {
  email: '',
  password: '',
  confirmPassword: '',
  acceptTerms: false,
};

const RegisterUser: React.FunctionComponent = () => {
  const [serverError, setServerError] = React.useState<string>();
  const [success, setSuccess] = React.useState<boolean>();
  const { t } = useTranslation();

  const registerSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required(t('account.validation.emailRequired')),
    password: Yup.string()
      .required(t('account.validation.passwordRequired'))
      .nullable()
      .matches(/\w*[a-z]\w*/, t('account.validation.invalidPasswordSmallLetter'))
      .matches(/\w*[A-Z]\w*/, t('account.validation.invalidPasswordCapitalLetter'))
      .matches(/\d/, t('account.validation.invalidPasswordNumber'))
      .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, t('account.validation.invalidPasswordSpecial'))
      .min(6, ({ min }) => t('account.validation.passwordLength', { min })),
    confirmPassword: Yup.string()
      .nullable()
      .oneOf([Yup.ref('password')], t('account.validation.passwordsNotMatch'))
      .required(t('account.validation.confirmPasswordRequired')),
    acceptTerms: Yup.boolean()
      .required('The terms and conditions must be accepted.')
      .oneOf([true], 'The terms and conditions must be accepted.'),
  });

  const createUser = async (values: any) => {
    try {
      setSuccess(false);
      setServerError(undefined);
      await AuthService.registerUser(values, localStorage.getItem('theme') || '');
      setSuccess(true);
    } catch (exception) {
      const errors = PoorMansError(exception);
      setServerError(errors ? errors : 'Technical error, try again later');
    }
  };

  return (
    <Container>
      <CenterColumn>
        <H2>Register User Account</H2>
        <Formik initialValues={initialValues} validationSchema={registerSchema} onSubmit={(values) => createUser(values)}>
          {({ isSubmitting }) => (
            <StyledForm>
              <TextInput label="Email Address" name="email" type="email" placeholder="email@example.com" />
              <InfoBox>
                <img src={infoIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
                <div>{t('account.setPasswordWarning')}</div>
              </InfoBox>
              <PasswordInput label={t('account.password')} name="password" />
              <PasswordInput label={t('account.confirmPassword')} name="confirmPassword" />
              <Checkbox
                label={acceptLabel}
                inputStyle={{ margin: '0.75rem 0.75rem 0.75rem 0' }}
                name="acceptTerms"
                id="acceptTerms"
              />
              <br />
              <Button disabled={isSubmitting || success}>{isSubmitting ? 'Creating...' : 'Create'}</Button>
              <br />
            </StyledForm>
          )}
        </Formik>
        {serverError && !success ? (
          <ErrorText>{serverError}</ErrorText>
        ) : success ? (
          <>
            <h2>You have successfully created an account!</h2>
            <SuccessText>
              We have sent you an email with a link to confirm your account. Follow the link to complete the registration.
            </SuccessText>
          </>
        ) : (
          <InfoBox>
            <img src={infoIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
            <div>Once you have created your user account, you will be able to connect to or create an organization.</div>
          </InfoBox>
        )}
      </CenterColumn>
    </Container>
  );
};

export const acceptLabel = (
  <>
    I have read and accept&nbsp;<A href=" https://www.environdec.com/general-terms">terms of use</A>&nbsp;and&nbsp;
    <A href="https://www.environdec.com/general-terms#privacyandcookies">privacy policy</A>
  </>
);

export default RegisterUser;
