import { Theme } from 'react-toastify';
import { LicenseeModel } from 'services/EpdClient';
import themeAuOldUI from 'styles/theme/au';
import themeAuNewUI from 'styles/theme/auNew';
import themeBrOldUI from 'styles/theme/br';
import themeBrNewUI from 'styles/theme/brNew';
import themeEuOldUI from 'styles/theme/eu';
import themeEuNewUI from 'styles/theme/euNew';

export enum ProcessStatus {
  None,
  Fetching,
  Error,
  Success,
}

export enum Role {
  EPDOwner = '6462962C-5D80-E911-AD06-000D3AB8D68E',
  EPDDeveloper = '6562962c-5d80-e911-ad01-000d3ab8d68e',
  EPDVerifier = '6562962c-5d80-e911-ad03-000d3ab8d68e',
  LCAAuthor = '6362962c-5d80-e911-ad05-000d3ab8d68e',
  Admin = '6262962C-5D80-E911-AD04-000D3AB8D68E',
}

export enum RoleName {
  EPDOwner = 'EPD owner',
}

export enum Command {
  None,
  Inquiry,
  Connect,
}

export const REMOVE_FILE = 'RemoveFile';
export const MAX_IMG_SIZE = 1024 * 1024;
export const MAX_FILE_SIZE = 10000000;
export const MAX_COMPANY_LOGO_SIZE = 1024 * 1024;

export const CUSTOM_RESULT_KEYS = {
  A4C4: '1',
  MODELING: '2',
  BIOGENIC: '3',
  ELECTRICITY: '4',
  BEST: '5',
  WORST: '6',
};

export const EPD_DEVELOPMENT_VERIFICATION_TEMPLATES_URL =
  'https://environdec.com/resources/documentation#epddevelopmentandverificationtemplates';
export const EPD_LCA_TEMPLATES_URL = 'https://environdec.com/resources/documentation#exceltemplatesformachinereadableepds';
export const AuLicenseesCountry = ['AU', 'NZ'];
export const EUCountries = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];

export const REG_URL =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?(\/)?$/gm;

//eslint-disable-next-line
export const REG_PHONE = /^[\+]?\d{1,3}(-| )?([(]([0-9]){2,3}[)])?[-\s\.]?([0-9]{2,3}[-\s\.]?){2,}[0-9]{0,6}$/;
export const defaultThemeCode = 'GL';
export const defaultTheme = themeEuOldUI;
export const defaultLicensee: LicenseeModel = {
  id: '',
  code: defaultThemeCode,
  name: 'defaultLicensee',
  supportEmail: 'info@environdec.com',
  localHomepage: '',
};
export const themeOptions = [
  { name: 'Australasia', code: 'AU', themes: { new: themeAuNewUI, old: themeAuOldUI } },
  { name: 'Brazil', code: 'BR', themes: { new: themeBrNewUI, old: themeBrOldUI } },
  { name: 'International', code: 'GL', themes: { new: themeEuNewUI, old: themeEuOldUI } },
];

export const COMPANIES_WITH_COMPILER = [
  'Company Demo',
  'Demo Organisation Test',
  'Demo Verifier',
  'EPD International Test AB',
  'SigmaTest',
  'test',
  'Test',
  'Test Emails',
  'Test New Emails',
  'Test Company Demo',
  'Test Company Kateryna',
  'test org',
  "Test Company for API testing (don't change)",
  'DG1',
  'DGtest',
  '!!!demo111',
  '3M Company (USA)',
  'EPD International AB'
];
