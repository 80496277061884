import Checkbox from 'components/form/Checkbox';
import { editAdminSelectStyles } from 'components/form/EditAdminSelectStyles';
import LargeRadiusTextInput from 'components/form/LargeRadiusTextInput';
import SortedTable from 'components/form/SortedTable';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { FixedLabel } from 'pages/pcrWizard/PcrWizard';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import { CompanyListModel, RoleModel } from 'services/EpdClient';
import AdminService from 'services/admin/AdminService';
import { useTheme } from 'styled-components';
import {
  AdminBox,
  AdminSearchGridForm,
  ButtonSmall,
  Container,
  EditFull,
  ErrorMessage,
  H2,
  LabelBox,
  Message,
  MessageBox,
  ResultMessageBox,
  SearchBox,
  StyledReactSelect,
  ValueBox,
} from 'styles/Styles.styled';
import Spinner from 'styles/spinner.styled';
import { Theme } from 'styles/theme';
import { Option } from 'types/types';
import * as Yup from 'yup';

import { ValueArchived, ValueRole } from './style';

const AdminCompanies: React.FunctionComponent = () => {
  const history = useHistory();
  const [roles, setRoles] = useState<RoleModel[]>();
  const [selectedCompanies, setSelectedCompanies] = useState<CompanyListModel[]>();
  const [underSearch, setUnderSearch] = useState<boolean>(false);
  const [companiesNumber, setCompaniesNumber] = useState<number | undefined>();
  const { changeCompany } = useContext(CompanyContext);
  const theme = useTheme() as Theme;

  const titles = [
    { label: 'Name', field: 'name', fieldStyle: { width: '50%' } },
    { label: 'Website', field: 'website', fieldStyle: { width: '50%', wordBreak: 'break-all' } },
  ];

  useEffect(() => {
    // const loadCompanies = async () => {
    //   const result = await AdminService.getCompanies(undefined, undefined, undefined);
    //   setCompanies(result);
    // };
    const loadRoles = async () => {
      const result = await AdminService.getRoles();
      setRoles(result);
    };
    //loadCompanies();
    loadRoles();
  }, []);

  const getItemInfo = (item: any) => {
    changeCompany && changeCompany(item.id);
    history.push(EpdLinks.editCompany(item.id));
    return;
  };

  const initialValues = {
    company: '',
    role: {},
    includeArchived: false,
    onlyHolding: false,
  };

  const validationSchema = Yup.object({
    company: Yup.string(),
    role: Yup.object().nullable(),
    includeArchived: Yup.boolean().notRequired(),
    onlyHolding: Yup.boolean().notRequired(),
  });
  const onSearch = (values: any) => {
    const fetchCompany = async () => {
      const getCompany = await AdminService.getCompanies(
        values.company,
        values.role?.value,
        values.includeArchived,
        values.onlyHolding
      );
      setSelectedCompanies(getCompany);
      setCompaniesNumber(getCompany.length);
      setUnderSearch(true);
    };
    fetchCompany();
  };

  return (
    <Container>
      <EditFull>
        <H2>Organizations</H2>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSearch}>
          <AdminBox>
            <AdminSearchGridForm style={{ maxWidth: '90%' }}>
              <LabelBox>
                <FixedLabel>Name</FixedLabel>
              </LabelBox>
              <ValueBox>
                <LargeRadiusTextInput
                  style={{ boxSizing: 'border-box', width: '100%' }}
                  name="company"
                  type="text"
                  placeholder="Search by company name..."
                />
              </ValueBox>
              <LabelBox>
                <FixedLabel>Role</FixedLabel>
              </LabelBox>
              <ValueRole>
                <StyledReactSelect
                  name="role"
                  styles={editAdminSelectStyles}
                  options={roles?.map((role) => {
                    return { value: role.id, label: role.name } as Option;
                  })}
                  isClearable={true}
                  isMulti={false}
                  defaultValue="Select..."
                ></StyledReactSelect>
              </ValueRole>
              <ValueArchived>
                <Checkbox label="Include archived" name="includeArchived" />
              </ValueArchived>
              <ValueArchived>
                <Checkbox label="Only holding companies" name="onlyHolding" />
              </ValueArchived>

              <SearchBox>
                <ButtonSmall style={{ padding: '0.5rem 1rem', borderRadius: '3px' }} type="submit">
                  Search
                </ButtonSmall>
              </SearchBox>
            </AdminSearchGridForm>
          </AdminBox>
        </Formik>
        {underSearch && isEmpty(selectedCompanies) && (
          <ErrorMessage style={{ marginTop: '1rem', color: 'black' }}>No records found.</ErrorMessage>
        )}
        {underSearch && !selectedCompanies && (
          <div>
            <Spinner />
          </div>
        )}
        {underSearch && !isEmpty(selectedCompanies) && (
          <MessageBox>
            <ResultMessageBox>
              {companiesNumber === 1 ? (
                <Message style={{ marginLeft: 0 }}>Found {companiesNumber} match</Message>
              ) : (
                <Message>Found {companiesNumber} matches</Message>
              )}
            </ResultMessageBox>
            <div
              style={{
                display: 'inline-block',
                gridColumnStart: '2',
                gridColumnEnd: '3',
                minWidth: '100%',
                padding: '1px',
                borderBottomLeftRadius: '3px',
                borderBottom: `1px solid ${theme.colors.mediumGrey}`,
                alignSelf: 'end',
                justifySelf: 'end',
              }}
            ></div>
          </MessageBox>
        )}
        {!isEmpty(selectedCompanies) && (
          <div style={{ marginTop: '-1.2rem' }}>
            <SortedTable columns={titles} collection={selectedCompanies ?? []} onClick={getItemInfo} />
          </div>
        )}
      </EditFull>
    </Container>
  );
};

export default AdminCompanies;
