import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import React from 'react';
import { useEpdCpcrSpecificData } from 'services/api/queries';
import styled from 'styled-components';
import { ExpandablePanelHeaderName, FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { CpcrSpecificTableModel } from 'types/types';

import { ExpandableHelpBox } from '../help-boxes';
import DynamicGrid from './grids/lca-results/DynamicGrid';
import FixedGrid from './grids/lca-results/FixedGrid';
import GridPanel from './panels/expandable/GridPanel';
import ToggleButton from './panels/toggleable/ToggleButton';

const TechnicalPerformanceTab: React.FunctionComponent<{
  epdVersionId: string;
  validationState: any;
}> = ({ epdVersionId, validationState = {} }) => {
  const cpcrSpecificData = useEpdCpcrSpecificData(epdVersionId!).data;

  const headerHelpBoxTemplate = (options: any, header: string, tooltip?: string, tooltipPosition?: any | undefined) => {
    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HelpBoldText>{header}</HelpBoldText>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
        </ExpandablePanelHeaderName>
        <ToggleButton options={options} withMargins />
      </div>
    );
  };

  return (
    <>
      <FieldPanelFullWidth style={{ gridRowGap: 0 }}>
        <ExpandableHelpBox
          headerTemplate={(options) => headerHelpBoxTemplate(options, 'Technical characteristics and performance')}
          className="w-full"
          toggleable
        >
          <span>
            Product standards (including c-PCRs) and other relevant documents may contain relevant information to properly
            define the technical performance.
          </span>
        </ExpandableHelpBox>
      </FieldPanelFullWidth>

      {cpcrSpecificData?.tables?.map((t: CpcrSpecificTableModel) => (
        <FieldPanelFullWidth key={t.tableCode}>
          {t.isFixed ? (
            <FixedGrid
              epdVersionId={epdVersionId}
              tableTitle={t.tableTitle}
              tableCode={t.tableCode}
              columns={cpcrSpecificData.columns?.filter((x: any) => x.tableCode == t.tableCode)}
              value={cpcrSpecificData.data?.filter((x: any) => x.tableCode == t.tableCode) || []}
              errors={null}
            />
          ) : (
            <GridPanel title={t.tableTitle} tooltip={t.tableDescription}>
              <DynamicGrid
                epdVersionId={epdVersionId}
                tableTitle={'Products'}
                tableCode={t.tableCode}
                addDefaultEmptyRow={!t.isFixed}
                columns={cpcrSpecificData.columns?.filter((x: any) => x.tableCode == t.tableCode)}
                value={cpcrSpecificData.data?.filter((x: any) => x.tableCode == t.tableCode)}
                errors={null}
              />
            </GridPanel>
          )}
        </FieldPanelFullWidth>
      ))}
    </>
  );
};

const HelpBoldText = styled.span`
  font-weight: 600;
`;

export default TechnicalPerformanceTab;
