import { editAdminSelectStyles } from 'components/form/EditAdminSelectStyles';
import LargeRadiusTextInput from 'components/form/LargeRadiusTextInput';
import SortedTable from 'components/form/SortedTable';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { FixedLabel } from 'pages/pcrWizard/PcrWizard';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import { CompanyListModel, EPDListModel } from 'services/EpdClient';
import AdminEpdService from 'services/admin/AdminEpdService';
import AdminService from 'services/admin/AdminService';
import { useTheme } from 'styled-components';
import {
  AdminBox,
  AdminSearchGridForm,
  ButtonSmall,
  Container,
  EditFull,
  ErrorMessage,
  H2,
  InfoText,
  LabelBox,
  Message,
  MessageBox,
  ResultMessageBox,
  SearchBox,
  StyledReactSelect,
  ValueBox,
} from 'styles/Styles.styled';
import Spinner from 'styles/spinner-left.styled';
import { Theme } from 'styles/theme';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

import { Option } from '../../../types/types';

const AdminEpds: React.FunctionComponent = () => {
  const history = useHistory();
  // const [status, setStatus] = useState<[]>([]);
  const [companies, setCompanies] = useState<CompanyListModel[]>([]);
  const [epds, setEpds] = useState<EPDListModel[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [epdsNumber, setEpdsNumber] = useState<number | undefined>();
  const theme = useTheme() as Theme;

  const titles = [
    { label: 'Reg. No.', field: 'fullIdentificationNumber' },
    { label: 'Title', field: 'title' },
    { label: 'EPD Owner', field: 'epdOwnerCompanyName' },
    { label: 'Status', field: 'status' },
  ];

  const initialValues = {
    registrationNumber: null,
    title: '',
    searchedCompanyName: '',
    selectedCompany: null,
  };

  const validationSchema = Yup.object({
    registrationNumber: Yup.number().nullable(),
    title: Yup.string().nullable(),
    searchedCompanyName: Yup.string().nullable(),
    selectedCompany: Yup.object().nullable(),
  });

  const onSearch = async (rowValues: any) => {
    let values = {
      ...rowValues,
    };

    try {
      setIsLoading(true);
      let allEpds: any = await AdminEpdService.findEpds(
        values?.selectedCompany?.value,
        values?.selectedCompany?.label ?? values?.searchedCompanyName ?? '',
        values?.title,
        values?.registrationNumber
      );
      allEpds = allEpds.map((epd: any) => {
        const statusElementStyle = () => {
          switch (epd.status) {
            case 'Registered':
              return {
                margin: '0 10px 0 0',
                padding: '5px 2px',
                color: 'white',
                backgroundColor: `${theme.colors.regionColorLight}`,
                borderRadius: '3px',
                textAlign: 'center',
              };
            case 'Reserved':
              return {
                margin: '0 10px 0 0',
                padding: '5px 2px',
                color: 'white',
                backgroundColor: `${theme.colors.darkGray}`,
                borderRadius: '3px',
                textAlign: 'center',
              };
            case 'Deregistered':
              return {
                margin: '0 10px 0 0',
                padding: '5px 2px',
                color: 'white',
                backgroundColor: `${theme.colors.lightOrange}`,
                borderRadius: '3px',
                textAlign: 'center',
              };
          }
        };
        const statusElement = () => {
          return <div style={statusElementStyle() as any}>{epd.status}</div>;
        };
        return { ...epd, status: statusElement() };
      });
      setEpds(allEpds);
      setEpdsNumber(allEpds.length);
    } catch (error) {
      setErrorMessage(PoorMansError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const openEpd = (item: any) => {
    history.push(EpdLinks.editEpd(item.id));
  };

  useEffect(() => {
    // const loadStatus = () => {
    //   const items = Object.values(EPDStatus as any);
    //   setStatus(items as []);
    // };
    const loadAccounts = async () => {
      const companyList = await AdminService.getCompanies();
      setCompanies(companyList);
    };
    // loadStatus();
    loadAccounts();
  }, []);

  if (!initialValues) {
    return (
      <div style={{ marginTop: '1rem' }}>
        <Spinner />
      </div>
    );
  }
  return (
    <Container>
      <EditFull>
        <H2>EPDs</H2>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSearch}>
          <AdminBox>
            <AdminSearchGridForm>
              <LabelBox>
                <FixedLabel>Registration number</FixedLabel>
              </LabelBox>
              <ValueBox>
                <LargeRadiusTextInput
                  style={{ boxSizing: 'border-box', width: '100%' }}
                  name="registrationNumber"
                  type="number"
                  placeholder="Search by Registration Number..."
                />
              </ValueBox>
              <LabelBox>
                <FixedLabel>Title</FixedLabel>
              </LabelBox>
              <ValueBox>
                <LargeRadiusTextInput
                  style={{ boxSizing: 'border-box', width: '100%' }}
                  name="title"
                  type="text"
                  placeholder="Search by EPD title..."
                />
              </ValueBox>
              <LabelBox>
                <FixedLabel>Organization Name</FixedLabel>
              </LabelBox>
              <ValueBox>
                <LargeRadiusTextInput
                  style={{ boxSizing: 'border-box', width: '100%' }}
                  name="searchedCompanyName"
                  type="text"
                  placeholder="Search by Organization name..."
                />
              </ValueBox>
              <LabelBox>
                <FixedLabel>Organization</FixedLabel>
              </LabelBox>
              <ValueBox>
                <StyledReactSelect
                  isMulti={false}
                  name="selectedCompany"
                  styles={editAdminSelectStyles}
                  isClearable={true}
                  defaultValue="Select..."
                  options={companies.map((company) => {
                    return { value: company.id, label: company.name } as Option;
                  })}
                ></StyledReactSelect>
              </ValueBox>
              <SearchBox>
                <ButtonSmall style={{ padding: '0.5rem 1rem', borderRadius: '3px' }} type="submit">
                  Search
                </ButtonSmall>
              </SearchBox>
            </AdminSearchGridForm>
          </AdminBox>
        </Formik>
        {isLoading && (
          <div style={{ marginTop: '1rem' }}>
            <Spinner />
          </div>
        )}
        {!isEmpty(epds) && !isLoading && (
          <MessageBox>
            <ResultMessageBox>
              {epdsNumber === 1 ? (
                <Message style={{ marginLeft: 0 }}>Found {epdsNumber} match</Message>
              ) : (
                <Message>Found {epdsNumber} matches</Message>
              )}
            </ResultMessageBox>
            <div
              style={{
                display: 'inline-block',
                gridColumnStart: '2',
                gridColumnEnd: '3',
                minWidth: '100%',
                padding: '1px',
                borderBottomLeftRadius: '3px',
                borderBottom: `1px solid ${theme.colors.mediumGrey}`,
                alignSelf: 'end',
                justifySelf: 'end',
              }}
            ></div>
          </MessageBox>
        )}
        {errorMessage && isEmpty(epds) && (
          <ErrorMessage style={{ marginTop: '1rem', color: 'black' }}> {errorMessage}</ErrorMessage>
        )}
        {isEmpty(epds) && epds !== null && !isLoading && (
          <InfoText>No records found. Or you are not authorized to see them.</InfoText>
        )}
        {!isEmpty(epds) && !isLoading && (
          <div style={{ marginTop: '-1.2rem' }}>
            <SortedTable columns={titles} collection={epds ?? []} onClick={openEpd} />
          </div>
        )}
      </EditFull>
    </Container>
  );
};

export default AdminEpds;
