import { ReactComponent as ExpandIcon } from 'assets/images/icons/small-button/expand.svg';
import ErrorFallback from 'components/error-fallback/ErrorFallback';
import { Column, ColumnEvent, ColumnProps } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTableValueArray } from 'primereact/datatable';
import { Row } from 'primereact/row';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useUpdateLcaCustomResultGridDataRow } from 'services/api/mutationsLcaResults';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';
import useIsReadOnlyMode from 'components/v2/compiler/hooks/useIsReadOnlyMode';
import MaximizedDialog from 'components/v2/compiler/panels/expandable/MaximizedDialog';
import { DataTableStyled, GridButtonGreen } from '../../StyledGrid';

type TProps = {
  customResultId: string;
  value?: DataTableValueArray | undefined;
  columns?: ColumnProps[] | undefined;
  notes?: { note: React.ReactNode | string; description: React.ReactNode | string }[] | undefined;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};
const LcaCustomFixedResultsGrid = ({
  customResultId,
  value,
  columns,
  notes,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
}: TProps) => {
  const updateMutation = useUpdateLcaCustomResultGridDataRow(customResultId);
  const [visible, setVisible] = useState(false);
  const isReadOnly = useIsReadOnlyMode() || disabled;

  const rowClassName = () => ({ 'p-disabled': isReadOnly && !preferEnabledAppearanceEvenWhenDisabled });

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <HeaderCaptionSemiBold className="white-space-nowrap">Alternative GWP-biogenic results</HeaderCaptionSemiBold>
      <span style={{ display: 'flex', columnGap: '5px' }}>
        {!visible && <GridButtonGreen title="Expand" icon={<ExpandIcon />} onClick={() => setVisible(true)} />}
      </span>
    </div>
  );

  const footerGroup = notes ? (
    <ColumnGroup>
      {notes.map((x, i) => (
        <Row key={i}>
          <Column footer={x.note} className="p-row-note" />
          <Column footer={x.description} colSpan={columns?.length} className="p-row-note" />
        </Row>
      ))}
    </ColumnGroup>
  ) : null;

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;
    updateMutation.mutate(rowData);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <MaximizedDialog title="" visible={visible} onHide={() => setVisible(false)}>
        <DataTableStyled
          value={value}
          dataKey="indicatorId"
          tableStyle={{ minWidth: '49rem' }}
          showGridlines
          footerColumnGroup={footerGroup}
          editMode="cell"
          header={header}
          rowClassName={rowClassName}
        >
          {columns?.map((x) => (
            <Column
              key={x.field}
              {...x}
              onCellEditComplete={x.editor ? onCellEditComplete : undefined}
              {...(isReadOnly && { editor: undefined })}
            />
          ))}
        </DataTableStyled>
      </MaximizedDialog>
      <DataTableStyled
        value={value}
        dataKey="indicatorId"
        tableStyle={{ minWidth: '49rem' }}
        showGridlines
        footerColumnGroup={footerGroup}
        editMode="cell"
        header={header}
        rowClassName={rowClassName}
      >
        {columns?.map((x) => (
          <Column
            key={x.field}
            {...x}
            onCellEditComplete={x.editor ? onCellEditComplete : undefined}
            {...(isReadOnly && { editor: undefined })}
          />
        ))}
      </DataTableStyled>
    </ErrorBoundary>
  );
};

export default LcaCustomFixedResultsGrid;
