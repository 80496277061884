import LargeRadiusTextInput from 'components/form/LargeRadiusTextInput';
import { Form } from 'formik';
import styled from 'styled-components';
import { Container, Label } from 'styles/Styles.styled';

export const FixedLabel = styled(Label)`
  flex: 0 0 200px;
  align-self: center;
  margin: 0.5rem 1rem 0.5rem 0;

  > svg {
    margin: 0 0.5rem;
  }

  > div + svg {
    margin: 0;
  }
`;

export const EmailValue = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 3;
  }
`;

export const ArchivedLabel = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 3;
    grid-column-end: 4;
  }
`;

export const ArchivedValue = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 4;
    grid-column-end: 5;
  }
`;

export const ButtonsContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 5;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const TwoColumnsDiv = styled(Container)`
  justify-content: flex-start;
  flex-direction: column;
  margin: 0;
  padding: 0 10px;
  justify-content: space-between;
  ${(props) => props.theme.media.desktop} {
    flex-direction: row;
  }
`;

export const Half = styled.div`
  flex: 0 0 100%;
  margin-bottom: 2rem;
  ${(props) => props.theme.media.desktop} {
    flex: 0 0 48%;
    margin-bottom: 0;
  }
`;

export const AdminGridForm = styled(Form)`
  display: grid;
  grid-template-columns: 3fr 6fr 1fr;
  gap: 10px;
  justify-content: start;
  align-items: center;
  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 2fr 7fr 0.5fr;
  }
  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 3fr 6fr 0.5fr;
  }
`;

export const AdminGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 6fr 1fr;
  gap: 10px;
  justify-content: start;
  align-items: center;
  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 2fr 7fr 0.5fr;
  }
  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 3fr 6fr 0.5fr;
  }
`;

export const AdminTextInput = styled(LargeRadiusTextInput)`
  box-sizing: border-box;
  width: 100%;
  grid-column-start: 2;
  grid-column-end: 3;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 5;
  }
`;

export const CompaniesListBox = styled.div`
  min-height: 12rem;
  max-height: 27rem;
  overflow-y: auto;
  width: 100%;
  grid-column-start: 1;
  grid-template-rows: min-content;
  grid-auto-rows: min-content;
  grid-column-end: 3;
  box-sizing: border-box;
  background-color: #f9f9f9;
  border: 1px solid ${(props) => props.theme.colors.mediumGrey};
  padding: 24px !important;

  display: grid;
  grid-template-columns: 9fr 1fr;
  gap: 10px;
  justify-content: start;
  align-items: center;
  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 9fr 0.5fr;
  }
  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 9fr 0.5fr;
    grid-column-end: 8;
  }
`;

export const AddCompanyBox = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 8;
  }
`;

export const ImageRemove = styled.div`
  display: flex;
  align-self: center;
  width: fit-content;
  cursor: pointer;
`;

export const ValueRole = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 6;
  }
`;

export const ValueArchived = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  padding-left: 0;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 6;
    grid-column-end: 8;
    padding-left: 20%;
  }
`;

export const FirstCheckboxValue = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;

  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 3;
  }
`;

export const SecondCheckboxLabel = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 4;
    grid-column-end: 5;
  }
`;

export const SecondCheckboxValue = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;

  ${(props) => props.theme.media.desktop} {
    grid-column-start: 5;
    grid-column-end: 6;
    justify-self: end;
    align-self: end;
  }
`;
