import { LcaResultModel } from 'types/types';
import LcaResultsGrid from './LcaResultsGrid';
import LcaResultsGridPdf from './LcaResultsGridPdf';
import { LcaResultsGridColumns, LcaResultsGridColumnsPdf } from './LcaResultsGridColumns';

type TProps = {
  rows: LcaResultModel[] | undefined;
  isForPdf?: boolean;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};

const LcaResultsGridAdditionalMandatory = ({
  rows,
  isForPdf,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
}: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description: 'GWP-GHG = Global warming potential greenhouse gas.',
    },
    {
      note: 'Note',
      description:
        'The GWP-GHG indicator is termed GWP-IOBC/GHG in the ILCD+EPD+ data format. The indicator accounts for all greenhouse gases except biogenic carbon dioxide uptake and emissions and biogenic carbon stored in the product. As such, the indicator is identical to GWP-total except that the CF for biogenic CO2 is set to zero.',
    },
  ];

  if (isForPdf) {
    return <LcaResultsGridPdf value={rows} columns={LcaResultsGridColumnsPdf} notes={notes} />;
  }
  return (
    <LcaResultsGrid
      value={rows}
      columns={LcaResultsGridColumns}
      notes={notes}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaResultsGridAdditionalMandatory;
