import 'flag-icons/css/flag-icons.min.css';
import 'primeflex/primeflex.css';

import GuideText from 'components/v2/GuideText';
import { toaster } from 'components/v2/toast';
import { ProcessStatus } from 'constants/constants';
import { themeOptions } from 'constants/constants';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CompanyService from 'services/CompanyService';
import { AdminLicenseeModel, UpdateLicenseeModel, VerifierType } from 'services/EpdClient';
import AdminLicenseesService from 'services/admin/AdminLicenseesService';
import AdminService from 'services/admin/AdminService';
import AdminUserService from 'services/admin/AdminUserService';
import styled from 'styled-components';
import { ButtonSmall } from 'styles/Styles.styled';
import { Option } from 'types/types';

import AddLicenseeDialog from './AddLicenseeDialog';
import EditLicenseeDialog from './EditLicenseeDialog';
import EmptyLicenseeList from './EmptyLicenseeList';
import LicenseeList from './LicenseeList';

const ManageLicensees = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [licensees, setLicensees] = useState<AdminLicenseeModel[]>([]);
  const [showAddLicenseeDialog, setShowAddLicenseeDialog] = useState<boolean>(false);
  const [showEditLicenseeDialog, setShowEditLicenseeDialog] = useState<boolean>(false);
  const [selectedLicensee, setSelectedLicensee] = useState<AdminLicenseeModel | null>(null);
  const [countriesDs, setCountriesDs] = useState<Option[]>([]);
  const [individualVerifiersDs, setIndividualVerifiersDs] = useState<Option[]>([]);
  const [certificationBodyVerifiersDs, setCertificationBodyVerifiersDs] = useState<Option[]>([]);
  const [usersDs, setUsersDs] = useState<Option[]>([]);

  const fetchAllCountries = async () => {
    const countries = await AdminService.getCountries(undefined);
    setCountriesDs(
      countries.map((country) => {
        return { value: country.id, label: country.name } as Option;
      })
    );
  };

  const fetchAllUsers = async () => {
    const result = await AdminUserService.getUsers();
    setUsersDs(
      result
        .filter((u) => u.firstName != null)
        .map((user) => {
          return { value: user.id, label: `${user.firstName} ${user.lastName}` } as Option;
        })
    );
  };

  const fetchAllVerifiers = async () => {
    const result = await CompanyService.getCompanyVerifiers(undefined, undefined);
    setIndividualVerifiersDs(
      result
        .filter((x) => x.verifierType == VerifierType.Individual)
        .map((v, index) => {
          return { value: v.id, label: v.name } as Option;
        })
    );

    setCertificationBodyVerifiersDs(
      result
        .filter((x) => x.verifierType == VerifierType.Body)
        .map((v, index) => {
          return { value: v.id, label: v.name } as Option;
        })
    );
  };

  const fetchLicensees = async () => {
    setStatus(ProcessStatus.Fetching);
    try {
      const result = await AdminLicenseesService.getLicensees();
      setLicensees(result);
      setStatus(ProcessStatus.Success);
    } catch (error) {
      console.error(error);
      setStatus(ProcessStatus.Error);
    }
  };

  const handleEditLicensee = (licensee: AdminLicenseeModel) => {
    setSelectedLicensee(licensee);
    setShowEditLicenseeDialog(true);
  };

  const createLicensee = async (values: any) => {
    let model = {
      name: values.name,
      countries: values.countries.map((i: any) => i.value),
      users: values.users.map((i: any) => i.value),
      theme: values.theme,
      supportEmail: values.supportEmail,
      localHomepage: values.localHomepage,
      hasVerifiersList: values.hasVerifiersList,
      licenseeIndividualVerifiers: values.licenseeIndividualVerifiers.map((i: any) => i.value),
      licenseeCertificationBodyVerifiers: values.licenseeCertificationBodyVerifiers.map((i: any) => i.value),
    };
    setStatus(ProcessStatus.Fetching);
    try {
      const result = await AdminLicenseesService.createLicensee(model);
      setStatus(ProcessStatus.Success);
      setShowAddLicenseeDialog(false);
      toaster({ severity: 'success', summary: 'Success', details: 'Licensee has been created' });
      fetchLicensees();
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'Licensee creation error' });
      console.error(error);
      setStatus(ProcessStatus.Error);
    }
  };

  const updateLicensee = async (values: any) => {
    let model: UpdateLicenseeModel = {
      id: selectedLicensee?.id || '',
      name: values.name,
      countries: values.countries.map((i: any) => i.value),
      users: values.users.map((i: any) => i.value),
      theme: values.theme,
      supportEmail: values.supportEmail,
      localHomepage: values.localHomepage,
      hasVerifiersList: values.hasVerifiersList,
      licenseeIndividualVerifiers: values.licenseeIndividualVerifiers.map((i: any) => i.value),
      licenseeCertificationBodyVerifiers: values.licenseeCertificationBodyVerifiers.map((i: any) => i.value),
    };
    setStatus(ProcessStatus.Fetching);
    try {
      const result = await AdminLicenseesService.updateLicensee(selectedLicensee?.id || '', model);
      setStatus(ProcessStatus.Success);
      setShowEditLicenseeDialog(false);
      toaster({ severity: 'success', summary: 'Success', details: 'Licensee has been updated' });
      fetchLicensees();
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'Licensee update error' });
      console.error(error);
      setStatus(ProcessStatus.Error);
    }
  };

  useEffect(() => {
    fetchLicensees();
    fetchAllCountries();
    fetchAllUsers();
    fetchAllVerifiers();
  }, []);

  if (status === ProcessStatus.Fetching) {
    return <ProgressSpinner />;
  }

  return (
    <>
      <ConfirmDialog />
      <div className="flex justify-content-between w-full py-2">
        <PageTitle>{t('manageLicensees.licensees')}</PageTitle>
        <ButtonSmall
          style={{ paddingLeft: '1.4rem', paddingRight: '1.4rem', alignSelf: 'center' }}
          onClick={() => setShowAddLicenseeDialog(true)}
        >
          {t('manageLicensees.createLicensee')}
        </ButtonSmall>
        <AddLicenseeDialog
          show={showAddLicenseeDialog}
          onHide={() => setShowAddLicenseeDialog(false)}
          onSubmit={createLicensee}
          countriesDs={countriesDs}
          usersDs={usersDs}
          themesDs={themeOptions}
          verifiersDs={individualVerifiersDs}
          certificationBodyDs={certificationBodyVerifiersDs}
        />
        <EditLicenseeDialog
          show={showEditLicenseeDialog}
          item={selectedLicensee!}
          onHide={() => setShowEditLicenseeDialog(false)}
          onSubmit={updateLicensee}
          countriesDs={countriesDs}
          usersDs={usersDs}
          themesDs={themeOptions}
          verifiersDs={individualVerifiersDs}
          certificationBodyDs={certificationBodyVerifiersDs}
        />
      </div>

      <GuideText value={'Here you can manage the organizations which are related to the regional Licensees.'} />

      {licensees.length > 0 ? <LicenseeList licensees={licensees} fetchLicensees={fetchLicensees} handleEditLicensee={handleEditLicensee} /> : <EmptyLicenseeList />}

      <div className="card" style={{ width: '100%' }}></div>
    </>
  );
};

const PageTitle = styled.div`
  //font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  /* Primary/Black */
  color: #151515;
`;

export default ManageLicensees;
